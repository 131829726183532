import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import useRequest, { client } from "../../utils/useRequest";
import toastr from "toastr";
import EditClientComponent from "./EditClientComponent";

const EditClient = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const { data, mutate } = useRequest({
    method: "GET",
    url: `/api/clients/${params.id}/edit`,
  });

  const handleSubmit = async (values: any) => {
    const response = await client.put(`/api/clients/${params.id}`, values);
    if (response?.data?.status) {
      toastr.success(response?.data.message);
      navigate(`/${role}/clients`);
    } else {
      toastr.error(response?.data.message);
    }
  };
  console.log(data);
  return (
    <Layout isLoading={!data?.data}>
      <div className="card py-2">
        <h3 className="mb-3">Update Client</h3>
        <EditClientComponent
          onSubmit={handleSubmit}
          initialValue={data?.data}
        />
      </div>
    </Layout>
  );
};

export default EditClient;
