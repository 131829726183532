import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/auth/Login";
import Layout from "./component/layout/Layout";
import PrivateRoute from "./PrivateRoute";
import { useSelector } from "react-redux";
import Dashboard from "./pages/dashboard/Dashboard";
import Claims from "./pages/claim/Claims";
import Page404 from "./pages/error/Page404";
import PermissionRoute from "./PermissionRoute";
import {
  CLAIM_ADD,
  CLAIM_BROWSE,
  CLAIM_EDIT,
  CLIENT_ADD,
  CLIENT_BROWSE,
  CLIENT_EDIT,
  INSURANCE_ADD,
  INSURANCE_BROWSE,
  INSURANCE_EDIT,
  PHYSICIAN_ADD,
  PHYSICIAN_BROWSE,
  PHYSICIAN_EDIT,
  PROCESSOR_ADD,
  PROCESSOR_BROWSE,
  PROCESSOR_EDIT,
  USER_ADD,
  USER_BROWSE,
  USER_EDIT,
} from "./utils/Role";
import AddClaim from "./pages/claim/AddClaim";
import EditClaim from "./pages/claim/EditClaim";
import Physician from "./pages/physician/Physician";
import AddPhysicians from "./pages/physician/AddPhysicians";
import EditPhysicians from "./pages/physician/EditPhysicians";
import Insurance from "./pages/insurance/Insurance";
import AddInsurance from "./pages/insurance/AddInsurance";
import EditInsurance from "./pages/insurance/EditInsurance";
import Client from "./pages/client/Client";
import AddClient from "./pages/client/AddClient";
import EditClient from "./pages/client/EditClient";
import HcpcsCodes from "./pages/hcpcs/HcpcsCodes";
import HcpcsCodeState from "./pages/hcpcs/HcpcsCodeState";
import RuralZipCodes from "./pages/hcpcs/RuralZipCodes";
import Manufacturer from "./pages/Manufacturer/Manufacturer";
import Category from "./pages/Manufacturer/Category";
import SubCategory from "./pages/Manufacturer/SubCategory";
import ProductSpeciality from "./pages/Manufacturer/ProductSpeciality";
import Fitters from "./pages/Manufacturer/Fitters";
import ClaimReport from "./pages/claimReport/ClaimReport";
import Processor from "./pages/processor/Processor";
import AddProcessor from "./pages/processor/AddProcessor";
import EditProcessor from "./pages/processor/EditProcessor";
import User from "./pages/user/User";
import EditUser from "./pages/user/EditUser";
import AddUser from "./pages/user/AddUser";
import ClaimPaymentList from "./pages/claim/ClaimPaymentList";
import DuplicateClaim from "./pages/claim/DuplicateClaim";

const AllRoutes = () => {
  const { role } = useSelector((state: any) => state.roles);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path={`${role}/dashboard`} element={<Dashboard />} />
          <Route
            path={`${role}/claims`}
            element={
              <PermissionRoute pass={CLAIM_BROWSE}>
                <Claims />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/duplicate-claim`}
            element={
              <PermissionRoute pass={CLAIM_BROWSE}>
                <DuplicateClaim />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/claims/create`}
            element={
              <PermissionRoute pass={CLAIM_ADD}>
                <AddClaim />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/claims/:id/edit`}
            element={
              <PermissionRoute pass={CLAIM_EDIT}>
                <EditClaim />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/physicians`}
            element={
              <PermissionRoute pass={PHYSICIAN_BROWSE}>
                <Physician />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/physicians/create`}
            element={
              <PermissionRoute pass={PHYSICIAN_ADD}>
                <AddPhysicians />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/physicians/:id/edit`}
            element={
              <PermissionRoute pass={PHYSICIAN_EDIT}>
                <EditPhysicians />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/claim-payment-list`}
            element={
              <PermissionRoute pass={CLAIM_BROWSE}>
                <ClaimPaymentList />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/insurances`}
            element={
              <PermissionRoute pass={INSURANCE_BROWSE}>
                <Insurance />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/insurances/create`}
            element={
              <PermissionRoute pass={INSURANCE_ADD}>
                <AddInsurance />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/insurances/:id/edit`}
            element={
              <PermissionRoute pass={INSURANCE_EDIT}>
                <EditInsurance />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/clients`}
            element={
              <PermissionRoute pass={CLIENT_BROWSE}>
                <Client />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/clients/create`}
            element={
              <PermissionRoute pass={CLIENT_ADD}>
                <AddClient />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/clients/:id/edit`}
            element={
              <PermissionRoute pass={CLIENT_EDIT}>
                <EditClient />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/hcpcs-codes`}
            element={
              <PermissionRoute pass={CLIENT_EDIT}>
                <HcpcsCodes />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/hcpcs-state-code`}
            element={
              <PermissionRoute pass={CLIENT_EDIT}>
                <HcpcsCodeState />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/rural-zip-codes`}
            element={
              <PermissionRoute pass={CLIENT_EDIT}>
                <RuralZipCodes />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/manufacturers/home`}
            element={
              <PermissionRoute pass={CLIENT_EDIT}>
                <Manufacturer />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/manufacturers/category`}
            element={
              <PermissionRoute pass={CLIENT_EDIT}>
                <Category />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/manufacturers/sub-category`}
            element={
              <PermissionRoute pass={CLIENT_EDIT}>
                <SubCategory />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/manufacturers/product-speciality`}
            element={
              <PermissionRoute pass={CLIENT_EDIT}>
                <ProductSpeciality />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/manufacturers/fitters`}
            element={
              <PermissionRoute pass={CLIENT_EDIT}>
                <Fitters />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/claim-report`}
            element={
              <PermissionRoute pass={CLAIM_BROWSE}>
                <ClaimReport />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/processors`}
            element={
              <PermissionRoute pass={PROCESSOR_BROWSE}>
                <Processor />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/processors/:id/edit`}
            element={
              <PermissionRoute pass={PROCESSOR_EDIT}>
                <EditProcessor />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/processors/create`}
            element={
              <PermissionRoute pass={PROCESSOR_ADD}>
                <AddProcessor />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/users`}
            element={
              <PermissionRoute pass={USER_BROWSE}>
                <User />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/users/:id/edit`}
            element={
              <PermissionRoute pass={USER_EDIT}>
                <EditUser />
              </PermissionRoute>
            }
          />
          <Route
            path={`${role}/users/create`}
            element={
              <PermissionRoute pass={USER_ADD}>
                <AddUser />
              </PermissionRoute>
            }
          />

          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
