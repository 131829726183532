import React from "react";
import { Button as DefaultButton } from "reactstrap";

interface ButtonProps {
  color?: string;
  title: string;
  isLoading?: boolean;
  type?: any;
}

const LoadingButton: React.FC<ButtonProps> = ({
  color,
  title,
  isLoading,
  type,
}) => {
  return (
    <DefaultButton
      disabled={isLoading}
      className="btn-sm"
      color={color ?? "primary"}
      type={type || "submit"}
    >
      {title}
      {isLoading && (
        <div className="spinner-grow spinner-grow-sm mx-2" role="status"></div>
      )}
    </DefaultButton>
  );
};

export default LoadingButton;
