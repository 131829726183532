import React, { Suspense, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
interface LayoutProps {
  children: any;
  isLoading?: boolean;
  heading?: string;
  AddButton?: any;
  breadCrum?: any;
  extra?: any;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  isLoading,
  heading,
  AddButton,
  breadCrum,
  extra,
}) => {
  const [toggle, setToggle] = React.useState(false);
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("dark-mode") == "dark" ? true : false
  );

  useEffect(() => {}, [darkMode]);
  return (
    <div className={`wrapper ${darkMode ? "dark-theme" : ""}`}>
      <Sidebar toggle={toggle} />
      <div className="main" style={{ marginLeft: toggle ? "0px" : "254px" }}>
        <Navbar
          setToggle={() => setToggle(!toggle)}
          mode={darkMode}
          changeMode={(mode: any) => {
            localStorage.setItem("dark-mode", mode ? "dark" : "light");
            setDarkMode(mode);
          }}
        />
        <main className="content">
          <div className="container-fluid p-0">
            {isLoading ? (
              <div style={{ left: "50%", top: "50%" }} className="main-loader">
                <Spinner
                  animation="grow"
                  size="sm"
                  variant="primary opacity-20"
                />
                <Spinner
                  animation="grow"
                  size="sm"
                  variant="secondary opacity-20"
                />
                <Spinner
                  animation="grow"
                  size="sm"
                  variant="success opacity-20"
                />
                <Spinner
                  animation="grow"
                  size="sm"
                  variant="danger opacity-20"
                />
                <Spinner
                  animation="grow"
                  size="sm"
                  variant="warning opacity-20"
                />
                <Spinner animation="grow" size="sm" variant="info opacity-20" />
                <Spinner
                  animation="grow"
                  size="sm"
                  variant="primary opacity-20"
                />
                <Spinner animation="grow" size="sm" variant="dark opacity-20" />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between mb-3">
                  <h1 className="h3 mb-3">
                    <strong>
                      {!!breadCrum
                        ? breadCrum
                            .map((item: any, index: number) => {
                              return (
                                <a
                                  key={index}
                                  className={
                                    !!item.to ? "text-primary" : "text-muted"
                                  }
                                  onClick={() => {
                                    if (!!item?.to) {
                                      navigate(`/${role}${item.to}`);
                                    }
                                  }}
                                >
                                  {" "}
                                  {item.title}
                                </a>
                              );
                            })
                            .reduce((prev: any, curr: any) => [
                              prev,
                              " / ",
                              curr,
                            ])
                        : heading}
                    </strong>
                  </h1>
                  <div className="d-flex justify-content-end">
                    {extra}
                    {!!AddButton && AddButton}
                  </div>
                </div>
                <div className="row">
                  {children}
                </div>
              </>
            )}
          </div>
        </main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Layout;
