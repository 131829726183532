import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";

const Client = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li onClick={() => navigate(`/${role}/clients/${params.id}/edit`)}>
            <a className="dropdown-item">Edit Client</a>
          </li>
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 70,
    },
    { field: "id", headerName: "Client ID", width: 70 },
    { field: "company_name", headerName: "Company Name", width: 230 },
  ];

  let url = "/api/clients";
  return (
    <DatatableFilter
      heading="Clients"
      columns={columns}
      url={url}
      addButton={true}
    />
  );
};

export default Client;
