import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";

const HcpcsCodes = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li onClick={() => navigate(`/${role}/physicians/${params.id}/edit`)}>
            <a className="dropdown-item">Edit Physician</a>
          </li>
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "hcpcs_code", headerName: "HCPCS Code", width: 130 },
    {
      field: "hcpcs_code_medicare_description",
      headerName: "Description",
      width: 330,
    },
    { field: "import_year", headerName: "Import Year", width: 130 },
  ];

  let url = "/api/hcpcs-codes";
  return (
    <DatatableFilter
      heading="Hcpcs Code"
      columns={columns}
      url={url}
      addButton={true}
    />
  );
};

export default HcpcsCodes;
