import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";

const User = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li onClick={() => navigate(`/${role}/users/${params.id}/edit`)}>
            <a className="dropdown-item">Edit </a>
          </li>
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 20,
    },
    { field: "name", headerName: "Name", width: 200},
    { field: "email", headerName: "Email", width: 200},
    { field: "phone", headerName: "Phone", width: 200 },
    { field: "birthday", headerName: "Birthday", width: 200,renderCell: (row: any) => (row.row?.birthday ? row.row?.birthday  : "--")  },
    { field: "is_deactivated", headerName: "Status", width: 200,renderCell: (row: any) => (row.row?.is_deactivated ? "Inactive" : "Active") },
    { field: "client_name", headerName: "Client", width: 200 },
  ];

  let url = "/api/users";
  return (
    <DatatableFilter
      heading="Users"
      columns={columns}
      url={url}
      addButton={true}
    />
  );
};

export default User;
