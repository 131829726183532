import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import GoogleDocsViewer from "react-google-docs-viewer";
const FileViewer = ({ docs }: { docs: any }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Button size="sm" color="primary" onClick={() => setToggle(!toggle)}>
        <i className="fa fa-eye"></i>
      </Button>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>{docs.file_name}</ModalHeader>
        <ModalBody>
          <GoogleDocsViewer width="100%" height="70vh" fileUrl={docs.bucket} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FileViewer;
