import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  border: "2px dashed #79301d",
  height: 130,
  padding: 15,
} as React.CSSProperties;

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
  justifyContent: "center",
} as React.CSSProperties;

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
} as React.CSSProperties;

const img = {
  display: "block",
  width: "auto",
  height: "100%",
} as React.CSSProperties;

interface FileDropzoneProps {
  onChange: any;
  media?: any;
  multiple?: boolean;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  onChange,
  media,
  multiple,
}) => {
  const [files, setFiles] = React.useState(media ?? []);

  useEffect(() => {
    if (media.length < 1) {
      setFiles([]);
    }
  }, [media]);

  const onDrop = async (acceptedFiles: any, rejectedFiles: any) => {
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    onChange(acceptedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: multiple,
    accept: {
      "image/*": [".png"],
      "text/*": [".pdf", ".doc", ".docx", ".msg"],
    },
  });

  const thumbs = (files || []).map((m: any, index: number) => {
    return (
      <div style={thumb} key={index}>
        <div style={thumbInner}>
          <img
            src={m.type.includes("image") ? m.preview : "/img/file.jpg"}
            style={img}
          />
        </div>
        <span
          style={{
            position: "absolute",
            marginLeft: 80,
            marginTop: -10,
            cursor: "pointer",
            zIndex: 999,
          }}
          onClick={(e) => {
            e.stopPropagation();
            removeImage(m);
          }}
        >
          <i className="fa fa-times text-danger"></i>{" "}
        </span>
      </div>
    );
  });

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const removeImage = async (fileId: any) => {
    const currentMedia = _.filter(files, (f: any) => f.path !== fileId.path);
    setFiles(currentMedia);
  };

  return (
    <section style={{ margin: "30px 30px" }}>
      <div {...getRootProps({ className: "dropzone" })}>
        <aside style={thumbsContainer}>
          <input {...getInputProps()} />
          {files.length < 1 && (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
          {thumbs}
        </aside>
      </div>
    </section>
  );
};

export default FileDropzone;
