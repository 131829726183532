import React from "react";
import {
  CLAIM_BROWSE,
  INSURANCE_BROWSE,
  PHYSICIAN_BROWSE,
  PROCESSOR_BROWSE,
  USER_BROWSE,
} from "../../utils/Role";
import SideItem from "./SideItem";

interface SidebarProps {
  toggle?: boolean;
}
const Sidebar: React.FC<SidebarProps> = ({ toggle }) => {
  const sideMenu = [
    // {
    //   label: "Dashboard",
    //   to: "/dashboard",
    //   icon: "fa-dashboard",
    // },
    // {
    //   label: "Client",
    //   to: "/clients",
    //   icon: "fa-users",
    // },
    // {
    //   label: "Hcpcs",
    //   icon: "fa-snowflake-o",
    //   permission: CLAIM_BROWSE,
    //   openFor: ["hcpcs-codes", "hcpcs-state-code", "rural-zip-codes"],
    //   children: [
    //     {
    //       label: "Hcpcs Codes",
    //       to: "/hcpcs-codes",
    //       permission: CLAIM_BROWSE,
    //       icon: "fa-snowflake-o",
    //     },
    //     {
    //       label: "Hcpcs State Code",
    //       to: "/hcpcs-state-code",
    //       permission: PHYSICIAN_BROWSE,
    //       icon: "fa-minus-circle",
    //     },
    //     {
    //       label: "Rural Zip Codes",
    //       to: "/rural-zip-codes",
    //       permission: INSURANCE_BROWSE,
    //       icon: "fa-globe",
    //     },
    //   ],
    // },
    // {
    //   label: "Manufacturers",
    //   icon: "fa-diamond",
    //   permission: CLAIM_BROWSE,
    //   openFor: [
    //     "manufacturers/category",
    //     "manufacturers/home",
    //     "manufacturers/sub-category",
    //     "manufacturers/fitters",
    //     "manufacturers/product-speciality",
    //   ],
    //   children: [
    //     {
    //       label: "Manufacturers",
    //       to: "/manufacturers/home",
    //       permission: CLAIM_BROWSE,
    //       icon: "fa-diamond",
    //     },
    //     {
    //       label: "Category",
    //       to: "/manufacturers/category",
    //       permission: PHYSICIAN_BROWSE,
    //       icon: "fa-tags",
    //     },
    //     {
    //       label: "Sub Category",
    //       to: "/manufacturers/sub-category",
    //       permission: INSURANCE_BROWSE,
    //       icon: "fa-life-buoy",
    //     },
    //     {
    //       label: "Product Speciality",
    //       to: "/manufacturers/product-speciality",
    //       permission: INSURANCE_BROWSE,
    //       icon: "fa-product-hunt",
    //     },
    //     {
    //       label: "Fitters",
    //       to: "/manufacturers/fitters",
    //       permission: INSURANCE_BROWSE,
    //       icon: "fa-legal",
    //     },
    //   ],
    // },
    // {
    //   label: "1500 Claim",
    //   icon: "fa-user-plus",
    //   permission: CLAIM_BROWSE,
    //   openFor: ["claims", "physicians", "insurances", "claim-payment-list"],
    //   children: [
    //
    //
    //
    //   ],
    // },
    {
      label: "1500 Claim",
      to: "/claims",
      permission: CLAIM_BROWSE,
      icon: "fa-user-plus",
    },
    {
      label: "Duplicate Claim",
      to: "/duplicate-claim",
      permission: PHYSICIAN_BROWSE,
      icon: "fa-clone",
    },
    {
      label: "Physicians",
      to: "/physicians",
      permission: PHYSICIAN_BROWSE,
      icon: "fa-stethoscope",
    },
    {
      label: "Insurances",
      to: "/insurances",
      permission: INSURANCE_BROWSE,
      icon: "fa-ravelry",
    },
    {
      label: "Claim Report",
      to: "/claim-report",
      permission: CLAIM_BROWSE,
      icon: "fa-flag",
    },
    {
      label: "Claim Payment",
      to: "/claim-payment-list",
      permission: CLAIM_BROWSE,
      icon: "fa-cc-diners-club",
    },
    // {
    //   label: "Processor",
    //   icon: "fa-tachometer",
    //   to: "/processors",
    //   permission: PROCESSOR_BROWSE,
    // },
    // {
    //   label: "User",
    //   icon: "fa-user",
    //   to: "/users",
    //   permission: USER_BROWSE,
    // },
    // {
    //   label: "Product",
    //   children: [
    //     {
    //       label: "Categories",
    //       to: "/categories",
    //     },
    //     {
    //       label: "Product",
    //       to: "products",
    //     },
    //   ],
    // },
  ];
  return (
    <nav
      id="sidebar"
      className={`sidebar js-sidebar ${toggle ? "collapsed" : ""} `}
    >
      <div className="sidebar-content js-simplebar">
        <a className="sidebar-brand" href="index.html">
          <span className="align-middle">Eazemedical</span>
        </a>

        <ul className="sidebar-nav">
          {sideMenu.map((item: any, index: number) => {
            return <SideItem key={index} item={item} />;
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
