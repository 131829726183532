import React, { useState } from "react";
import { Field, FieldProps } from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import { Form } from "react-router-dom";

interface ToggleButtonFieldProps {
  label?: string;
  name: string;
  checked?: boolean;
  type?: string;
  tabIndex?: number;
  onChange?: any;
}
const ToggleButtonField: React.FC<ToggleButtonFieldProps> = ({
  label,
  name,
  checked,
  type,
  tabIndex,
    onChange,
}) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { touched, errors, setFieldValue },
        meta,
      }: FieldProps) => (
        <div>
          <FormGroup check>
            <Label check>
              <div className="form-check mt-3 form-switch">
                <input
                  {...field}
                  tabIndex={tabIndex}
                  className="form-check-input mt-1"
                  type="checkbox"
                  id={`${name}-flexSwitchCheckChecked`}
                  value="1"
                  checked={!!checked || false}
                  onChange={() => setFieldValue(name, !checked)}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckChecked"
                >
                  {label}
                </label>
              </div>
            </Label>
          </FormGroup>
          {(meta.touched || meta.error) && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default ToggleButtonField;
