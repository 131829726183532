import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Datatable from "../../component/datatable/Datatable";
import { client } from "../../utils/useRequest";

interface ChangeLogProps {
  modelName: string;
  subjectId: any;
}
const ChangeLog: React.FC<ChangeLogProps> = ({ modelName, subjectId }) => {
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const columns: GridColDef[] = [
    { field: "created_at", headerName: "Date", width: 130 },
    {
      field: "username",
      headerName: "Username",
      width: 130,
      renderCell: (row: any) => row.row.user?.name,
    },
    {
      field: "events",
      headerName: "Event",
      flex: 1,
      renderCell: (row: any) => {
        return (
          <div>
            {row.row?.event === "created" ? (
              <div>
                {row.row.user?.name} created <strong>{row.row.log_name}</strong>{" "}
                with id <strong>{row.row.subject_id}</strong>{" "}
              </div>
            ) : (
              <div>
                <table className="table table-responsive table-sm">
                  {Object.entries<any>(
                    row.row?.properties?.old || row.row?.properties?.attributes
                  ).map<any>((item: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td>
                          Changed <strong> {item[0]}</strong> from{" "}
                          <strong style={{ color: "#003e99" }}>
                            {item[1] ?? "Blank"}
                          </strong>{" "}
                          to{" "}
                          <strong style={{ color: "#38b82b" }}>
                            {row.row.properties.attributes[`${item[0]}`]}{" "}
                          </strong>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const fetchApi = async () => {
    setIsLoading(true);
    const response = await client.get(
      `api/activity-logs/${modelName}/show_details/${subjectId}`
    );
    if (response.status) {
      setData(response.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (toggle) {
      fetchApi();
    }
  }, [toggle]);
  return (
    <>
      <a className="dropdown-item" onClick={() => setToggle(!toggle)}>
        Change Log
      </a>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>{modelName}</ModalHeader>

        <ModalBody style={{ height: "50vh" }}>
          <DataGrid
            getRowHeight={() => "auto"}
            rows={data ?? []}
            columns={columns}
            hideFooter
            loading={isLoading}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            className="mt-3"
            onClick={() => setToggle(!toggle)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ChangeLog;
