import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import useRequest, { client } from "../../utils/useRequest";
import toastr from "toastr";
import EditProcessorComponent from "./EditProcessorComponent";

const EditProcessor = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const { data, mutate } = useRequest({
    method: "GET",
    url: `/api/processors/${params.id}/edit`,
  });

  const handleSubmit = async (values: any) => {
    const response = await client.put(`/api/processors/${params.id}`, values);
    if (response?.data?.status) {
      toastr.success(response?.data.message);
      navigate(`/${role}/processors`);
    } else {
      toastr.error(response?.data.message);
    }
  };
  return (
    <Layout isLoading={!data?.data}>
      <div className="card py-2">
        <h3 className="mb-3">Update Processor</h3>
        <EditProcessorComponent
          onSubmit={handleSubmit}
          initialValue={data?.data}
        />
      </div>
    </Layout>
  );
};

export default EditProcessor;
