import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import TextField from "../../component/form-component/TextField";
import * as yup from "yup";
import {
  Badge,
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import LoadingButton from "../../component/form-component/LoadingButton";
import SelectTable from "../../component/form-component/SelectTable";
import SelectCustom from "../../component/form-component/SelectCustom";
import CheckBoxField from "../../component/form-component/CheckBoxField";
import { client } from "../../utils/useRequest";
import SelectTextField from "../../component/form-component/SelectTextField";
import ToggleButtonField from "../../component/form-component/ToggleButtonField";
import moment, { now } from "moment";
import { useSelector } from "react-redux";
import toastr from "toastr";
import {useNavigate} from "react-router-dom";


const AddClaimValidationSchema = yup.object().shape({
  patient_first_name: yup.string().required("Patient First Name is required"),
  patient_last_name: yup.string().required("Patient Last Name is required"),
  date_received: yup.string().required("Date Referal Received is required"),
});
interface EditClaimComponentProps {
  initialValue?: any;
  onSubmit: any;
  reference?: React.Ref<any>;
  formChangeDetected?: any;
  reData?: any
}

const EditClaimComponent: React.FC<EditClaimComponentProps> = ({
  initialValue,
  onSubmit,
  reference,
  formChangeDetected,
  reData
}) => {
  const { user } = useSelector((state: any) => state.user);
  const submitRef = useRef<any>();
  const [checkedIds,setCheckedIds] = useState([] as any);
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);



  useImperativeHandle(
    reference,
    () => {
      return {
        submitRef: () => submitRef.current.handleSubmit(),
      };
    },
    []
  );

  const ProductCheckBoxAction = () => {
    return (
        <div className="table-action dropend mx-1 mt-1">
          <button
              type="button"
              className="btn btn-sm btn-primary"
              data-bs-toggle="dropdown"
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </button>
          <ul className="dropdown-menu" aria-labelledby="" style={{}}>
            <li>
              <a className="dropdown-item"
                  onClick={() => {
                      let checkedIds = [];
                      for (let i = 0; i < 6; i++) {
                        const checkboxes = document.querySelectorAll(`input[name="products[${i}][${i}]"]:checked`);
                        checkedIds.push(...Array.from(checkboxes).map(checkbox => checkbox.id));
                      }

                      if (checkedIds.length > 0) {
                        deleteProduct(checkedIds);
                      } else {
                        toastr.error("No rows are selected");
                      }
                  }}
              > Delete Products</a>
            </li>


          </ul>
        </div>
    );
  };

  const deleteProduct = async (ids: any[]) => {
    const response = await client.post(`/api/claim/delete-products`, {
      ids: ids,
    });
    if (response.status) {
      toastr.success("Claim Product Deleted Successfully");
      window.location.reload();
    }
  };

  const calculateTotaL = (values: any) => {
    const total = values?.reduce((acc: any, item: any) => {
      if (!!item.quantity && !!item.charges) {
        return (
          parseFloat(acc) + parseInt(item.quantity) * parseFloat(item.charges)
        );
      } else {
        return parseFloat(acc);
      }
    }, 0);

    return `${
      isNaN(total) ? "" : new Intl.NumberFormat("en-US").format(total)
    }`;
  };

  const dateDiff = (text: string, dos: any, dor: any) => {
    return `${text}: ${moment(dor).diff(moment(dos), "days")} days`;
  };

  const formik = useFormikContext();

  return (
    <Formik
      innerRef={submitRef}
      initialValues={{ ...initialValue }}
      enableReinitialize
      validationSchema={AddClaimValidationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <div className="card">
                <div className={"" + (values.id ? "card-header" : "")}>
                  <strong>{values.id == null ? "" : "B-" + values.id}</strong>
                  {values.created_by?.name && (
                    <Badge color="success" className="mx-1">
                      Entered By: {values.created_by?.name}
                    </Badge>
                  )}
                  {values.date_of_service && values.date_received && (
                    <Badge color="primary" className="mx-1">
                      <>
                        {dateDiff(
                          "Referral Time",
                          values.date_of_service,
                          values.date_received
                        )}
                      </>
                    </Badge>
                  )}
                  {values.date_of_service && (
                    <Badge color="primary" className="mx-1">
                      {dateDiff(
                        "Claim Time",
                        values.date_of_service,
                        moment.now()
                      )}
                    </Badge>
                  )}
                  {values.id && (
                    <Button
                      onClick={() => {
                        setFieldValue(
                          "checkoff_date",
                          moment(now()).format("YYYY-MM-DD")
                        );
                        setTimeout(() => handleSubmit(), 100);
                      }}
                      size="sm"
                      color="primary"
                      className="mx-3"
                    >
                      Check Off Now
                    </Button>
                  )}

                  {!!reData && <ListModal
                    title="Appeals"
                    button="Appeals"
                    list={reData?.appeals}
                  />}

                  <div className={"pull-right"}>
                    {!!initialValue?.id && (
                      <LoadingButton
                        title="Update Claim"
                        isLoading={isSubmitting}
                      />
                    )}
                  </div>
                </div>

                <div className="card-body">
                  <Row>
                    <Col md={3}>
                      <TextField
                        tabIndex={1}
                        label="Claim Number"
                        name="insurance_id_number"
                        type="text"
                        placeholder="Claim Number"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        type={"date"}
                        tabIndex={4}
                        label=" Date Referal Received"
                        name="date_received"
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <SelectCustom
                        tabIndex={7}
                        label="Missing Info"
                        name="missing_info"
                        options={[
                          { label: "Yes", value: "yes" },
                          { label: "No", value: "no" },
                        ]}
                      />
                    </Col>
                    <Col md={3}>
                      <SelectTable
                        tabIndex={10}
                        name="claim_status"
                        label="Claim Status"
                        table="claim_status"
                        value={values.claim_status}
                        onChange={(value: any)=> {
                          if([3,8].includes(value)){
                            setFieldValue('rework_date', moment(moment(), "YYYY-MM-DD").add(30, 'days').format("YYYY-MM-DD"))
                          }else{
                            setFieldValue('rework_date', moment(moment(), "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD"))
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {values.claim_status === 7 && (
                      <>
                        <Col md={6}>
                          <SelectCustom
                            label="Denial Reasons"
                            name="denial_reason"
                            options={[
                              {
                                label: "Timely Filing",
                                value: "Timely Filing",
                              },
                              {
                                label: "Not enough data to bill",
                                value: "Not enough data to bill",
                              },
                              {
                                label: "Unable to contact patient",
                                value: "Unable to contact patient",
                              },
                              {
                                label: "Unable to contact employer",
                                value: "Unable to contact employer",
                              },
                              {
                                label: "Not a Work Comp claim",
                                value: "Not a Work Comp claim",
                              },
                              { label: "Other", value: "Other" },
                            ]}
                          />
                        </Col>
                        <Col md={6}>
                          {values.denial_reason === "Other" && (
                            <TextField
                              type={"text"}
                              tabIndex={4}
                              label=" Other Reasons"
                              name="other_denial_reason"
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    {values.claim_status === 5 && (
                      <>
                        <Col md={6}>
                          <SelectCustom
                            label="Appeals Reasons"
                            name="appeal_reason"
                            options={[
                              {
                                label: "Timely Filing",
                                value: "Timely Filing",
                              },
                              {
                                label: "Lack of Preauthorization",
                                value: "Lack of Preauthorization",
                              },
                              {
                                label: "No Claim on File",
                                value: "No Claim on File",
                              },
                              {
                                label: "Manufacturer Invoice",
                                value: "Manufacturer Invoice",
                              },
                              { label: "Other", value: "Other" },
                            ]}
                          />
                        </Col>
                        <Col md={6}>
                          {values.appeal_reason === "Other" && (
                            <TextField
                              type={"text"}
                              tabIndex={4}
                              label=" Other Reasons"
                              name="other_appeal_reason"
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col md={3}>
                      <TextField
                        tabIndex={2}
                        label="Patient First Name"
                        name="patient_first_name"
                        type="text"
                        placeholder="Patient First Name"
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md="10">
                          <TextField
                            type={"date"}
                            tabIndex={5}
                            label="Date Submitted"
                            placeholder={"mm/dd/yyyy"}
                            name="date_submitted"
                          />
                        </Col>
                        <Col md="2" className="mt-5">
                          <ListModal
                            title="Date Submitted"
                            button="?"
                            list={reData?.date_submitted_list}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <TextField
                        type={"date"}
                        tabIndex={8}
                        label=" Check Off"
                        name="checkoff_date"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={11}
                        label="Action Needed"
                        name="action_needed"
                        type="text"
                        placeholder="Action Needed"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={3}
                        label="Patient Last Name"
                        name="patient_last_name"
                        type="text"
                        placeholder="Patient Last Name"
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        type={"date"}
                        tabIndex={6}
                        label="Rework Date"
                        name="rework_date"
                      />
                    </Col>
                    <Col md={3}>
                      <SelectTable
                        tabIndex={9}
                        name="qa_flag"
                        label="QA Flag"
                        table="processor"
                        value={values.qa_flag}
                        isClearable
                      />
                    </Col>
                    <Col md={3}>
                      <SelectTable
                        tabIndex={12}
                        name="processor_id"
                        label="Processor"
                        table="processor"
                        value={values.processor_id}
                        isClearable
                      />
                    </Col>
                    <Col className={"mt-2"}>
                      <CheckBoxField
                        tabIndex={13}
                        checked={!!values.pt_pay}
                        name="pt_pay"
                        label="PT Pay"
                      />
                      <CheckBoxField
                        tabIndex={14}
                        checked={!!values.emp_pay}
                        name="emp_pay"
                        label="EMP Pay"
                      />
                      <CheckBoxField
                        tabIndex={15}
                        checked={!!values.ins_pay}
                        name="ins_pay"
                        label="Ins Pay"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h4>Patient Data</h4>
                  {/* {!!initialValue?.id && (
                    <LoadingButton
                      title="Update Patient"
                      isLoading={isSubmitting}
                    />
                  )} */}
                </div>
                <div className="card-body">
                  <Row>
                    <Col md={3}>
                      <TextField
                        tabIndex={101}
                        label="Patient Phone"
                        name="patient_phone1"
                        type="text"
                        placeholder="Patient Phone"
                        phoneMask
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={104}
                        label="Patient City"
                        name="patient_city"
                        type="text"
                        placeholder="Patient City"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        type={"date"}
                        tabIndex={107}
                        label="Patient Birthday"
                        name="patient_birthday"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={110}
                        label="Insured Policy Group"
                        name="insured_policy"
                        type="text"
                        placeholder="Insured Policy Group"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={102}
                        label="Patient Address 1"
                        name="patient_address1"
                        type="text"
                        placeholder="Patient Address 1"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={105}
                        label="Patient State Code"
                        name="patient_state_code"
                        type="text"
                        placeholder="Patient State Code"
                      />
                    </Col>
                    <Col md={3}>
                      <SelectCustom
                        tabIndex={108}
                        label="Gender"
                        name="patient_gender"
                        options={[
                          { label: "Male", value: "M" },
                          { label: "Female", value: "F" },
                        ]}
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={112}
                        label="Patient Email"
                        name="patient_email"
                        type="text"
                        placeholder="Patient Email"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={103}
                        label="Patient Address 2"
                        name="patient_address2"
                        type="text"
                        placeholder="Patient Address 2"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={106}
                        label="Patient Postal Code"
                        name="patient_postal_code"
                        type="text"
                        placeholder="Patient Postal Code"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={109}
                        label="Patient SSN"
                        name="patient_ssn"
                        type="text"
                        placeholder="Patient SSN"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={113}
                        label="Patient Phone 2"
                        name="patient_phone2"
                        type="text"
                        placeholder="Patient Phone 2"
                        phoneMask
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h4>Insurance Company</h4>
                  {/* {!!initialValue?.id && (
                    <LoadingButton
                      title="Update Insurance Company"
                      isLoading={isSubmitting}
                    />
                  )} */}
                </div>
                <div className="card-body">
                  <Row>
                    <Col md={3}>
                      <SelectTextField
                        value={values.insurance_company_name}
                        tabIndex={201}
                        table="insurance_claims"
                        label="Insurance Company Name"
                        name="insurance_company_name"
                        onChange={(value: any) => {
                          if (!!value?.id) {
                            setFieldValue(
                              "insurance_company_name",
                              value?.insurance_company_name
                            );
                            setFieldValue(
                              "insurance_phone1",
                              value?.insurance_phone1
                            );
                            setFieldValue(
                              "insurance_address1",
                              value?.insurance_address1
                            );
                            setFieldValue(
                              "insurance_address2",
                              value?.insurance_address2
                            );
                            setFieldValue(
                              "insurance_city",
                              value?.insurance_city
                            );
                            setFieldValue(
                              "insurance_state_code",
                              value?.insurance_state_code
                            );
                            setFieldValue(
                              "insurance_postal_code",
                              value?.insurance_postal_code
                            );
                            setFieldValue(
                              "insurance_email",
                              value?.insurance_email
                            );
                            setFieldValue(
                              "insurance_website",
                              value?.insurance_website
                            );
                            setFieldValue(
                              "insurance_fax",
                              value?.insurance_fax
                            );
                            setFieldValue(
                              "insurance_claims_center",
                              value?.insurance_claims_center
                            );
                            setFieldValue(
                              "insurance_bill_review",
                              value?.insurance_bill_review
                            );
                            setFieldValue(
                              "insurance_submission_method",
                              value?.insurance_submission_method
                            );
                            setFieldValue(
                              "insurance_adjuster",
                              value?.insurance_adjuster
                            );
                            setFieldValue(
                              "insurance_adjuster_phone_number",
                              value?.insurance_adjuster_phone_number
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={205}
                        label="Insurance Adjuster Phone"
                        name="insurance_adjuster_phone_number"
                        type="text"
                        placeholder="Insurance Adjuster Phone"
                        phoneMask
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={209}
                        label="Submission Method"
                        name="insurance_submission_method"
                        type="text"
                        placeholder="Submission Method"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={213}
                        label="Insurance State Code"
                        name="insurance_state_code"
                        type="text"
                        placeholder="Insurance State Code"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={202}
                        label="Insurance Claims Center"
                        name="insurance_claims_center"
                        type="text"
                        placeholder="Insurance Claims Center"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={206}
                        label="Insurance Phone"
                        name="insurance_phone1"
                        type="text"
                        placeholder="Insurance Phone"
                        phoneMask
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={210}
                        label="Insurance Address 1"
                        name="insurance_address1"
                        type="text"
                        placeholder="Insurance Address 1"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={214}
                        label="Insurance Postal Code"
                        name="insurance_postal_code"
                        type="text"
                        placeholder="Insurance Postal Code"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={203}
                        label="Insurance Website"
                        name="insurance_website"
                        type="text"
                        placeholder="Insurance Website"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={207}
                        label="Insurance Fax"
                        name="insurance_fax"
                        type="text"
                        placeholder="Insurance Fax"
                        phoneMask
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={211}
                        label="Insurance Address 2"
                        name="insurance_address2"
                        type="text"
                        placeholder="Insurance Address 2"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={215}
                        label="Insurance Email"
                        name="insurance_email"
                        type="text"
                        placeholder="Insurance Email"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={204}
                        label="Insurance Adjuster"
                        name="insurance_adjuster"
                        type="text"
                        placeholder="Insurance Adjuster"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={208}
                        label="Insurance Bill Review"
                        name="insurance_bill_review"
                        type="text"
                        placeholder="Insurance Bill Review"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={212}
                        label="Insurance City"
                        name="insurance_city"
                        type="text"
                        placeholder="Insurance City"
                      />
                    </Col>
                    <Col md={12}>
                      <ToggleButtonField
                        tabIndex={216}
                        name="add_insurance_reference"
                        label="Add this insurance information for future reference."
                        checked={values.add_insurance_reference}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h4>Employer</h4>
                  {/* {!!initialValue?.id && (
                    <LoadingButton
                      title="Update Employer"
                      isLoading={isSubmitting}
                    />
                  )} */}
                </div>
                <div className="card-body">
                  <Row>
                    <Col>
                      <CheckBoxField
                        tabIndex={301}
                        name="employer_self_administered"
                        label="Self Administered"
                        checked={!!values.employer_self_administered}
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={305}
                        label="Employer Phone"
                        name="employer_phone"
                        type="text"
                        placeholder="Employer Phone"
                        phoneMask
                      />
                    </Col>

                    <Col md={3}>
                      <TextField
                        tabIndex={308}
                        label="Employer Address 2"
                        name="employer_address2"
                        type="text"
                        placeholder="Employer Address 2"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={311}
                        label="Employer Postal Code"
                        name="employer_postal_code"
                        type="text"
                        placeholder="Employer Postal Code"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={302}
                        label="Employer Company Name"
                        name="employer_name"
                        type="text"
                        placeholder="Employer Company Name"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={306}
                        label="Employer Phone 2"
                        name="employer_phone2"
                        type="text"
                        placeholder="Employer Phone 2"
                        phoneMask
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={309}
                        label="Employer Fax"
                        name="employer_fax"
                        type="text"
                        placeholder="Employer Fax"
                        phoneMask
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={312}
                        label="Employer City"
                        name="employer_city"
                        type="text"
                        placeholder="Employer City"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={303}
                        label="Employer Email"
                        name="employer_email"
                        type="text"
                        placeholder="Employer Email"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={307}
                        label="Employer Contact"
                        name="employer_contact"
                        type="text"
                        placeholder="Employer Contact"
                      />
                    </Col>

                    <Col md={3}>
                      <TextField
                        tabIndex={310}
                        label="Employer Address 1"
                        name="employer_address1"
                        type="text"
                        placeholder="Employer Address 1"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={313}
                        label="Employer State Code"
                        name="employer_state_code"
                        type="text"
                        placeholder="Employer State Code"
                      />
                    </Col>

                    <Col md={3}>
                      <TextField
                        tabIndex={304}
                        label="Employer Contact 2"
                        name="employer_contact2"
                        type="text"
                        placeholder="Employer Contact 2"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h4>Diagnosis</h4>
                  {/* {!!initialValue?.id && (
                    <LoadingButton
                      title="Update Diagnosis"
                      isLoading={isSubmitting}
                    />
                  )} */}
                </div>
                <div className="card-body">
                  <Row>
                    <Col md={3}>
                      <TextField
                        tabIndex={401}
                        label="ICD10 Code"
                        name="icd10_code"
                        type="text"
                        placeholder="ICD10 Code"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        type={"date"}
                        tabIndex={402}
                        label="Date Of Service"
                        name="date_of_service"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        type={"date"}
                        tabIndex={403}
                        label="Date Of Injury"
                        name="date_of_injury"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h4>Service Provider</h4>
                  {/* {!!initialValue?.id && (
                    <LoadingButton
                      title="Update Service Provider"
                      isLoading={isSubmitting}
                    />
                  )} */}
                </div>
                <div className="card-body">
                  <Row>
                    <Col md={3}>
                      <SelectTextField
                        value={values.service_company_name}
                        tabIndex={501}
                        table="physician_claims"
                        label="Service Provider Company Name"
                        name="service_company_name"
                        onChange={(value: any) => {
                          if (!!value?.id) {
                            setFieldValue(
                              "service_phone1",
                              value?.physician_phone1
                            );
                            setFieldValue(
                              "service_email",
                              value?.physician_email
                            );
                            setFieldValue("service_fax", value?.physician_fax);
                            setFieldValue(
                              "service_state_code",
                              value?.physician_state_code
                            );
                            setFieldValue(
                              "service_postal_code",
                              value?.physician_postal_code
                            );
                            setFieldValue(
                              "service_website",
                              value?.physician_website
                            );
                            setFieldValue(
                              "service_address1",
                              value?.physician_address1
                            );
                            setFieldValue(
                              "service_address2",
                              value?.physician_address2
                            );
                            setFieldValue(
                              "service_city",
                              value?.physician_city
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={504}
                        label="Service Provider Phone"
                        name="service_phone1"
                        type="text"
                        placeholder="Service Provider Phone"
                        phoneMask
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={507}
                        label="Service Provider Address 2"
                        name="service_address2"
                        type="text"
                        placeholder="Service Provider Address 2"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={510}
                        label="Service Provider Postal Code"
                        name="service_postal_code"
                        type="text"
                        placeholder="Service Provider Postal Code"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={502}
                        label="Physician NPI"
                        name="physician_npi"
                        type="text"
                        placeholder="Physician NPI"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={505}
                        label="Service Provider Fax"
                        name="service_fax"
                        type="text"
                        placeholder="Service Provider Fax"
                        phoneMask
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={508}
                        label="Service Provider City"
                        name="service_city"
                        type="text"
                        placeholder="Service Provider City"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={511}
                        label="Service Provider Email"
                        name="service_email"
                        type="text"
                        placeholder="Service Provider Email"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={503}
                        label="Refering Provider(Physician)"
                        name="refering_provider"
                        type="text"
                        placeholder="Refering Provider(Physician)"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={506}
                        label="Service Provider Address 1"
                        name="service_address1"
                        type="text"
                        placeholder="Service Provider Address 1"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={509}
                        label="Service Provider State Code"
                        name="service_state_code"
                        type="text"
                        placeholder="Service Provider State Code"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={512}
                        label="Service Provider Website"
                        name="service_website"
                        type="text"
                        placeholder="Service Provider Website"
                      />
                    </Col>
                    <Col md={12}>
                      <ToggleButtonField
                        tabIndex={513}
                        name="add_physician_reference"
                        label="Add this provider information for future reference."
                        checked={values.add_physician_reference}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h4>Products</h4>
                  {/* {!!initialValue?.id && (
                    <LoadingButton
                      title="Update Products"
                      isLoading={isSubmitting}
                    />
                  )} */}
                </div>
                <div className="card-body">
                  <table
                    className="table table-bordered"
                    id="claimProductTable"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Part Number</th>
                        <th scope="col">HCPCS Code</th>
                        <th scope="col">HCPCS Modifier</th>
                        <th scope="col">Units</th>
                        <th scope="col">Per Unit Charge</th>
                        <th scope="col">  <ProductCheckBoxAction/></th>
                      </tr>
                    </thead>
                    <tbody id="product-row">
                      {Array.from(Array(6)).map((x, i) => {
                        return (
                          <tr key={i} className="charge-row">
                            <th>
                              <TextField
                                type="text"
                                name={`products[${i}][part_number]`}
                              />
                            </th>
                            <td>
                              {/* <TextField
                                type="text"
                                name={`products[${i}][hcpcs_code]`}
                                
                              /> */}

                              <HcpcsCode
                                patientStateCode={values.patient_state_code}
                                dateOfService={values.date_of_service}
                                patientPostalCode={values.patient_postal_code}
                                name={`products[${i}][hcpcs_code]`}
                                unitPrice={(val: any) => {
                                  setFieldValue(
                                    `products[${i}][charges]`,
                                    `${val.toFixed(2)}`
                                  );
                                  setFieldValue(`products[${i}][quantity]`, 1);
                                }}
                              />
                            </td>
                            <td>
                              <TextField
                                type="text"
                                name={`products[${i}][hcpcs_modifier]`}
                              />
                            </td>
                            <td>
                              <TextField
                                type="number"
                                name={`products[${i}][quantity]`}
                              />
                            </td>
                            <td>
                              <TextField
                                type="number"
                                name={`products[${i}][charges]`}
                              />
                            </td>
                            <td>
                              {!!initialValue?.id &&
                                  !!values?.products[i] &&
                                  !!values?.products[i]["id"] && (
                                      <ViewCharge
                                          quantity={
                                              values?.products[i] &&
                                              values?.products[i]["quantity"]
                                          }
                                          id={
                                              values?.products[i] &&
                                              values?.products[i]["id"]
                                          }
                                      />
                                  )}
                              <CheckBoxField
                                  checked={values?.products && values?.products[i] && !!values.products[i][i]}
                                  name={`products[${i}][${i}]`}
                                  id={values?.products && values?.products[i] && values?.products[i]["id"]?values?.products[i]["id"]:null}
                                  // onChange={(e: any) => setCheckedIds(e.target.checked ? (values?.products[i] && values?.products[i]["id"]) : null)}
                              />

                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end">
                    <Col md="3">
                      <span>Total: {calculateTotaL(values.products)}</span>
                    </Col>
                  </div>
                </div>
              </div>
              <Row>
                <Col md={3}>
                  {!initialValue?.id && (
                    <LoadingButton title="Add Claim" isLoading={isSubmitting} />
                  )}
                </Col>
              </Row>
              {!!initialValue?.id && (
                <AutoSubmitToken
                  initialValue={initialValue}
                  onDetect={(val: any) => {
                    formChangeDetected(val);
                  }}
                />
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default EditClaimComponent;

interface HcpcsCodeProps {
  name: string;
  patientStateCode: string;
  unitPrice: any;
  dateOfService: string;
  patientPostalCode: string;
}
const HcpcsCode: React.FC<HcpcsCodeProps> = ({
  name,
  patientStateCode,
  unitPrice,
  dateOfService,
  patientPostalCode,
}) => {
  const onChangeCode = async (hcpcsCode: any) => {
    let year = moment(dateOfService ?? new Date(), "YYYY-MM-DD").year();
    if (!year) {
      year = new Date().getFullYear();
    }

    if (!!hcpcsCode) {
      const response = await client.get(
        `api/get-hcpcs-code-pricing/${hcpcsCode}/${patientStateCode}/0.25/${year}/${patientPostalCode}`
      );
      unitPrice(response.data);
    } else {
      unitPrice(0);
    }
  };

  return (
    <Field name={name}>
      {({
        field,
        form: { touched, errors, setFieldValue },
        meta,
      }: FieldProps) => (
        <div>
          <Input
            {...field}
            className="form-control"
            type={"text"}
            value={field.value || ""}
            onChange={(e: any) => {
              setFieldValue(name, e.target.value);
              onChangeCode(e.target.value);
            }}
          />
          {(meta.touched || meta.error) && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

interface ViewChargeProps {
  id: number;
  quantity: number;
}
const ViewCharge: React.FC<ViewChargeProps> = ({ id, quantity }) => {
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState([] as any);

  const fetchPrice = async () => {
    const response = await client.get(`/api/claim-pricing/${id}?q=${quantity}`);
    if (response.data.status) {
      setData(response.data.data);
    }
  };

  useEffect(() => {
    if (toggle) {
      fetchPrice();
    }
  }, [toggle]);

  return (
    <>
      <Button
        className={"pull-right"}
        size="sm"
        color="primary"
        onClick={() => setToggle(!toggle)}
      >
        <i className="fa fa-eye"></i>
      </Button>
      <Modal isOpen={toggle}>
        <ModalHeader>Price Break Down</ModalHeader>
        <ModalBody>
          <div className="card">
            <div className="card-header">Price Detail</div>
            <div className="card-body">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">HCPCS Code:</th>
                    <td>{data?.hcpcs_code}</td>
                  </tr>
                  <tr>
                    <th scope="col">HCPCS Description</th>
                    <td>{data?.hcpcs_description}</td>
                  </tr>
                  <tr>
                    <th scope="col">Quantity</th>
                    <td>{data?.quantity}</td>
                  </tr>
                  <tr>
                    <th scope="col">Unit Charge:</th>
                    <td>{data?.hcpcs_charge}</td>
                  </tr>
                  <tr>
                    <th scope="col">Subtotal:</th>
                    <td>
                      $
                      {parseFloat(data?.quantity) *
                        parseFloat(data?.hcpcs_charge)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setToggle(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

interface AutoSubmitTokenProps {
  onDetect: any;
  initialValue: any;
}
const AutoSubmitToken: React.FC<AutoSubmitTokenProps> = ({
  onDetect,
  initialValue,
}) => {
  const formik = useFormikContext();
  const [trigger, setTrigger] = useState(false);
  React.useEffect(() => {
    if (trigger) {
      onDetect(formik?.values);
    }
    setTrigger(true);
  }, [formik?.values]);
  return null;
};
interface ListModalProps {
  list: any;
  title: string;
  button: string;
}
const ListModal: React.FC<ListModalProps> = ({ list, title, button }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Badge
        color="secondary"
        onClick={() => setToggle(!toggle)}
        className="mx-3 cursor-pointer"
      >
        {button}
      </Badge>
      <Modal isOpen={toggle}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {list?.map((item: any, i: number) => {
            return (
              <Row key={i}>
                <Col md={4}>
                  <Badge>{!!item?.created_at ? moment(item?.created_at).format("MM/DD/YYYY") : 'N/A'}</Badge>
                </Col>
                <Col md={4}>{item?.other ? item?.other : item.value}</Col>
              </Row>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
