import React from "react";
import { Form, Formik } from "formik";
import TextField from "../../component/form-component/TextField";
import * as yup from "yup";
import { client } from "../../utils/useRequest";
import { loginFail, loginSuccess } from "../../store/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getUserProfile from "../../store/actions/userAction";
import getRoles from "../../store/actions/roleAction";
import LoadingButton from "../../component/form-component/LoadingButton";

const LoginValidationSchema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const Login = () => {
  const dispatch = useDispatch();
  const { error, isAuth } = useSelector((state: any) => state.auth);
  const { role } = useSelector((state: any) => state.roles);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!!isAuth && !!role) {
      if (isAuth) navigate(`${role}/claims`);
    } else {
      navigate("/");
    }
  }, [role]);

  const onSubmit = async (values: any) => {
    try {
      const response = await client.post(`/api/login`, values);
      if (response.status === 200) {
        dispatch<any>(getRoles());
        dispatch(loginSuccess());
        dispatch<any>(getUserProfile());
        localStorage.setItem("token", response.data.access_token);
        // navigate("/dashboard");
      }
    } catch (e) {
      dispatch(loginFail("Invalid Email or Password."));
    }
  };
  return (
    <main className="d-flex w-100">
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="text-center mt-4">
                <h1 className="h2">Welcome to Eaze Medical</h1>
                <p className="lead">Sign in to your account to continue</p>
              </div>

              <div className="card">
                <div className="card-body">
                  <div className="m-sm-4">
                    <div className="text-center">
                      <img
                        src="img/eaze_medical_solutions_logo.png"
                        alt="Eaze Medical Solutions"
                        className="img-fluid"
                        width="132"
                        height="132"
                      />
                    </div>
                    {error && (
                      <div className="alert text-danger mb-3 mt-3" role="alert">
                        {error}
                      </div>
                    )}
                    <Formik
                      initialValues={{ email: "", password: "" }}
                      validationSchema={LoginValidationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ handleSubmit, isSubmitting }) => {
                        return (
                          <Form onSubmit={handleSubmit}>
                            <TextField
                              label="Email Address"
                              name="email"
                              type="email"
                              placeholder="Email"
                            />
                            <TextField
                              label="Password"
                              name="password"
                              type="password"
                              placeholder="********"
                            />
                            <small>
                              <a href="index.html">Forgot password?</a>
                            </small>{" "}
                            <label className="form-check mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="remember-me"
                                name="remember-me"
                                defaultChecked
                              />
                              <span className="form-check-label">
                                Remember me
                              </span>
                            </label>
                            <div className="text-center mt-3">
                              <LoadingButton
                                title={"Submit"}
                                isLoading={isSubmitting}
                              />
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
