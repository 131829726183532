import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";

const Physician = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li onClick={() => navigate(`/${role}/physicians/${params.id}/edit`)}>
            <a className="dropdown-item">Edit Physician</a>
          </li>
        </ul>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 70,
    },
    { field: "id", headerName: "Physician ID", width: 70 },
    { field: "npi_number", headerName: "NPI Number", width: 130 },
    { field: "physician_name", headerName: "Name", width: 130 },
    { field: "physician_phone1", headerName: "Phone", width: 130 },
    { field: "physician_address1", headerName: "Address 1", width: 130 },
    { field: "physician_address2", headerName: "Address 2", width: 130 },
    { field: "physician_city", headerName: "City", width: 130 },
    { field: "physician_postal_code", headerName: "Postal Code", width: 130 },
    { field: "physician_email", headerName: "Email", width: 130 },
    { field: "physician_website", headerName: "Website", width: 130 },
    { field: "physician_company_name", headerName: "Company Name", width: 130 },
  ];

  let url = "/api/physicians";
  return (
    <DatatableFilter
      heading="Physicians"
      columns={columns}
      url={url}
      addButton={true}
    />
  );
};

export default Physician;
