import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import { client } from "../../utils/useRequest";
import EditPhysiciansComponent from "./EditPhysiciansComponent";
import toastr from "toastr";

const AddPhysicians = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const handleSubmit = async (values: any) => {
    const response = await client.post("/api/physicians", { ...values });
    if (response.data.status) {
      toastr.success(response?.data.message);
      navigate(`/${role}/physicians`);
    }
  };
  return (
    <Layout>
      <div className="card py-2">
        <h3 className="mb-3">Add New Physicians</h3>
        <EditPhysiciansComponent onSubmit={handleSubmit} />
      </div>
    </Layout>
  );
};

export default AddPhysicians;
