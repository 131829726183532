import React, { useState } from "react";
import { Field, FieldProps } from "formik";
import { FormGroup, Input, Label } from "reactstrap";
import { Form } from "react-router-dom";

interface CheckBoxFieldProps {
  id?:string;
  label?: string;
  name: string;
  checked?: boolean;
  type?: string;
  tabIndex?: number;
  onChange?: any;
  value?: any;
}
const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  id,
  label,
  name,
  checked,
  type,
  tabIndex,
  onChange,
  value
}) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { touched, errors, setFieldValue },
        meta,
      }: FieldProps) => (
        <div>
          <FormGroup check>
            <Label check>
              <Input
                {...field}
                tabIndex={tabIndex}
                id={id}
                value={value ?? 1}
                checked={!!checked || false}
                onChange={() => setFieldValue(name, !checked ? value ?? !checked : !checked)}
                type="checkbox"
              />
              {label}
            </Label>
          </FormGroup>
          {(meta.touched || meta.error) && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default CheckBoxField;
