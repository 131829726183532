import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import { client } from "../../utils/useRequest";
import toastr from "toastr";
import EditProcessorComponent from "./EditProcessorComponent";

const AddProcessor = () => {
  const [data, setData] = useState({} as any);


  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const handleSubmit = async (values: any) => {
    const response = await client.post("/api/processors", { ...values });
    if (response.data.status) {
      toastr.success(response?.data.message);
      navigate(`/${role}/processors`);
      // setData(...response.data.data);

    }
  };
  useEffect(() => {}, [data]);

  return (
    <Layout>
      <div className="card py-2">
        <h3 className="mb-3">Add New Processor</h3>
        <EditProcessorComponent onSubmit={handleSubmit}
          initialValue={{
          ...data,
            user_id: 2,

          }} />
      </div>
    </Layout>
  );
};

export default AddProcessor;
