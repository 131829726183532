import React from "react";
import { Field, FieldProps } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface DatePickerComponentProps {
  label?: string;
  name: string;
  type?: any;
  placeholder?: string;
  hidden?: boolean;
  value?: any;
  required?: boolean;
  tabIndex?: number;
}
const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  label,
  name,
  type,
  placeholder,
  hidden,
  value,
  required,
  tabIndex,
}) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { touched, errors, setFieldValue },
        meta,
      }: FieldProps) => (
        <div className={!!label ? "mt-3" : ""}>
          {!!label && (
            <label className="text-start mb-1 d-flex justify-content-start">
              {label}
              {required ? <h5 className="text-danger mx-2"> * </h5> : ""}
            </label>
          )}
          <DatePicker
            placeholderText="Click to select a date"
            tabIndex={tabIndex}
            className="form-control"
            onChange={(date: any) => {
              if (date instanceof Date) {
                // moment('2023-03-31 00:00:00').local().format("yyyy-MM-DD");
                // console.log(moment.tz('2023-03-31 15:00:00', 'YYYY-MM-DD HH:mm:ss', 'UTC').format('yyyy-MM-DD HH:mm:ss'));
                // console.log(moment(date).tz('America/Los_Angeles').format("yyyy-MM-DD"));
                // console.log(date);
                // console.log(date.getDate());

                // var month = date.getMonth() + 1; //months from 1-12
                // var day = date.getDate();
                // var year = date.getFullYear();

                // console.log(year+'-'+month+'-'+day);

                // setFieldValue(
                //   name,
                //   moment(date).local().format("yyyy-MM-DD")
                // );

                // console.log(new Date('2022-10-20'));
                date.setUTCHours(0, 0, 0, 0);
                setFieldValue(name, moment(date).format("MM/DD/YYYY"));
                // setFieldValue(name, year+'-'+month+'-'+day);
              }
              if (!date) {
                setFieldValue(name, null);
              }
            }}
            selected={
              !!field.value && moment(field.value).isValid()
                ? moment(field.value).toDate()
                : null
            }
            yearDropdownItemNumber={300}
            showYearDropdown
            dateFormat="MM/dd/yyyy"
          />
          {(meta.touched || meta.error) && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default DatePickerComponent;
