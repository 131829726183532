import { Field, FieldProps } from "formik";
import React from "react";
import Select from "react-select";

interface SelectCustomProps {
  name: string;
  label?: string;
  options: any;
  required?: boolean;
  tabIndex?: number;
}

const SelectCustom: React.FC<SelectCustomProps> = ({
  name,
  label,
  options,
  required,
  tabIndex,
}) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { touched, errors, setFieldValue },
        meta,
      }: FieldProps) => (
        <div className={!!label ? "mt-3" : ""}>
          {!!label && (
            <label className="text-start mb-1 d-flex justify-content-start">
              {label}{" "}
              {required ? <h5 className="text-danger mx-2"> * </h5> : ""}
            </label>
          )}
          <Select
            {...field}
            tabIndex={tabIndex}
            options={options}
            isSearchable
            placeholder={`Select ${label}`}
            value={options.find((item: any) => item.value == field.value) || ""}
            onChange={(value: any) => setFieldValue(name, value?.value)}
          />
          {(meta.touched || meta.error) && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default SelectCustom;
