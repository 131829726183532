import { getUserPending, getUserSuccess, getUserFail } from "../slice/userSlice";
import { client } from '../../utils/useRequest'


const getUserProfile = () => async (dispatch) => {
    try{
        dispatch(getUserPending);
        const response = await getUser();
        if(!!response.data.status){
            return dispatch(getUserSuccess(response.data.data.user))
        }else{
            return dispatch(getUserFail('Fetching user failed'));
        }
    }catch(e){
        return dispatch(getUserFail(e.message));
    }
}

export default getUserProfile;

const getUser = async () => {

    try{
         const response = await client.get(`/api/user`)
        return response;
    }catch(e){
        
    }
  
}