import React, { useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Layout from "../../component/layout/Layout";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { client } from "../../utils/useRequest";
import CheckBoxField from "../../component/form-component/CheckBoxField";
import { Form, Formik } from "formik";
import LoadingButton from "../../component/form-component/LoadingButton";
import SelectCustom from "../../component/form-component/SelectCustom";
import TextField from "../../component/form-component/TextField";

const ClaimReport = () => {
  const dateFilter = [
    { label: "None", value: "none" },
    { label: "Date Referal Received", value: "date_referal_received" },
    { label: "Date Of Service", value: "date_of_service" },
    { label: "Date Submitted", value: "date_submitted" },
    { label: "Payment Received Date", value: "payment_receipt_date" },
    { label: "Client Paid Date", value: "client_paid_date" },
  ];

  const sortBy = [
    { label: "None", value: "none" },
    { label: "Date Referal Received", value: "date_referal_received" },
    { label: "Date Of Service", value: "date_of_service" },
    { label: "Date Submitted", value: "date_submitted" },
    { label: "Payment Received Date", value: "payment_receipt_date" },
    { label: "Client Paid Date", value: "client_paid_date" },
  ];

  const bergFilter = [
    { label: "Date Of Service", value: "dos" },
    { label: "Referal Date", value: "referal_date" },
    { label: "Payment Date", value: "payment_date" },
  ];
  return (
    <Layout heading="Claim Reports">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <span className="card-title mb-0">Claim Reports</span>
            </div>

            <div className="card-body">
              <div className="list-group">
                {/* <a className="list-group-item list-group-item-action d-flex justify-content-between">
                  <div className="col-md-6">1500 External Report</div>

                  <div className="col-md-2">
                    <ExportReport
                      filterBy={dateFilter}
                      title="1500 External Report"
                      type="external"
                    />
                  </div>
                </a> */}
                <a className="list-group-item list-group-item-action d-flex justify-content-between">
                  <div className="col-md-6">1500 Internal report</div>

                  <div className="col-md-2">
                    <ExportReport
                      filterBy={dateFilter}
                      sortBy={sortBy}
                      title="1500 Internal Report"
                      type="internal"
                    />
                  </div>
                </a>
                {/* <a className="list-group-item list-group-item-action d-flex justify-content-between">
                  <div className="col-md-6">1500 External Report - AZ</div>

                  <div className="col-md-2">
                    <ExportReport
                      filterBy={dateFilter}
                      title="1500 External Report - AZ"
                      type="external"
                      stateCode="AZ"
                    />
                  </div>
                </a> */}
                {/* <a className="list-group-item list-group-item-action d-flex justify-content-between">
                  <div className="col-md-6">1500 External Report - NC</div>

                  <div className="col-md-2">
                    <ExportReport
                      filterBy={dateFilter}
                      title="1500 External Report - NC"
                      type="external"
                      stateCode="NC"
                    />
                  </div>
                </a>
                <a className="list-group-item list-group-item-action d-flex justify-content-between">
                  <div className="col-md-4">Export Berg Report 1</div>

                  <div className="col-md-2">
                    <ExportReport
                      filterBy={bergFilter}
                      title="Export Elite Report 1"
                      type="elite-1"
                    />
                  </div>
                </a>
                <a className="list-group-item list-group-item-action d-flex justify-content-between">
                  <div className="col-md-4">Export Berg Report 2</div>

                  <div className="col-md-2">
                    <ExportReport
                      filterBy={bergFilter}
                      title="Export Elite Report 2"
                      type="elite-2"
                    />
                  </div>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ClaimReport;

interface ExportReportProps {
  title: string;
  filterBy: any;
  type: string;
  stateCode?: string;
  sortBy?: any
}
const ExportReport: React.FC<ExportReportProps> = ({
  title,
  filterBy,
  type,
  stateCode,
  sortBy
}) => {
  const [toggle, setToggle] = useState(false);
  const [startDate, setStartDate] = useState(null as any);
  const [endDate, setEndDate] = useState(null as any);
  const [filter, setFilter] = useState("" as any);
  const [isLoading, setIsLoading] = useState(false);

  const downloadReport = async (values: any) => {
    setIsLoading(true);

    const response = await client.post(
      `/api/download-claim-report`, values,
      { responseType: "blob" }
    );

    setToggle(false);
    setStartDate(null);
    setEndDate(null);
    setFilter("");
    setIsLoading(false);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${'1500-claims-' + moment().format('YYYY-MM-DD, h:m:s')}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  return (
    <>
      <Button size="sm" color="primary" onClick={() => setToggle(!toggle)}>
        Export
      </Button>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          <Formik
            initialValues={{
              headers: {
                id: "Eaze Claim Id",
                date_received: "Date Received",
                date_of_service: "Date Of Service",
                name: "Patient Name",
                part_number: "Part Number",
                hcpcs_code: "Billing Code",
                charges: "Billed Amount",
                service_location: "Service Location Name",
                claim_status: "Claim Status Bucket",
                payment_receipt_date: "Payment Received Date",
                payment_amount: "Total Payment Amount",
                client_paid_amount: "Amount Owed to Client (65%)",
                eaze_paid_amount: "Eaze Medical Payment Amount (35%)",
                client_paid_date: "Date Client Paid",
                notes: "Notes",
                processor: "Eaze Medical Solutions Rep",
                qa_flag: "QA Flag",
                qa_selection_date: "QA Flag Selection Date",
                patient_birthday: "Date Of Birth",
              }

            } as any}
            onSubmit={downloadReport}
          >
            {({ handleSubmit, isSubmitting, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <SelectCustom
                        label="Filter By"
                        name="filter_by"
                        options={filterBy}
                      />
                    </Col>
                    <Col md={6}>
                      <SelectCustom
                        label="Sort By"
                        options={sortBy}
                        name="sort_by"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">

                    <Col md={6}>
                      <TextField type="date" name="start_date" label="Start Date" />

                    </Col>
                    <Col md={6}>
                      <TextField type="date" name="end_date" label="End Date" />
                    </Col>
                  </Row>

                  <h4 className="mt-3">Columns</h4>
                  
                  <Row>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.id}
                        name="headers[id]"
                        label="Eaze Claim Id"
                        value="Eaze Claim Id"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.patient_birthday}
                        name="headers[patient_birthday]"
                        label="Date Of Birth"
                        value="Date Of Birth"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.date_received}
                        name="headers[date_received]"
                        label="Date Received"
                        value="Date Received"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.missing_info}
                        name="headers[missing_info]"
                        label="Missing Info"
                        value="Missing Info"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.date_of_service}
                        name="headers[date_of_service]"
                        label="Date Of Service"
                        value="Date Of Service"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.action_needed}
                        name="headers[action_needed]"
                        label="Action Needed"
                        value="Action Needed"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.name}
                        name="headers[name]"
                        label="Patient Name"
                        value="Patient Name"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.patient_phone1}
                        name="headers[patient_phone1]"
                        label="Patient Phone"
                        value="Patient Phone"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.part_number}
                        name="headers[part_number]"
                        label="Part Number"
                        value="Part Number"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.patient_address1}
                        name="headers[patient_address1]"
                        label="Patient Address 1"
                        value="Patient Address 1"
                      />
                    </Col>

                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.hcpcs_code}
                        name="headers[hcpcs_code]"
                        label="Billing Code"
                        value="Billing Code"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.insurance_id_number}
                        name="headers[insurance_id_number]"
                        label="Claim Number"
                        value="Claim Number"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.charges}
                        name="headers[charges]"
                        label="Billed Amount"
                        value="Billed Amount"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.date_submitted}
                        name="headers[date_submitted]"
                        label="Date Submitted"
                        value="Date Submitted"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.service_location}
                        name="headers[service_location]"
                        label="Service Location Name"
                        value="Service Location Name"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.rework_date}
                        name="headers[rework_date]"
                        label="Rework Date"
                        value="Rework Date"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.claim_status}
                        name="headers[claim_status]"
                        label="Claim Status Bucket"
                        value="Claim Status Bucket"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.patient_state_code}
                        name="headers[patient_state_code]"
                        label="Patient State"
                        value="Patient State"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.payment_receipt_date}
                        name="headers[payment_receipt_date]"
                        label="Payment Received Date"
                        value="Payment Received Date"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.insurance_company_name}
                        name="headers[insurance_company_name]"
                        label="Insurance Company Name"
                        value="Insurance Company Name"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.payment_amount}
                        name="headers[payment_amount]"
                        label="Total Payment Amount"
                        value="Total Payment Amount"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.employer_name}
                        name="headers[employer_name]"
                        label="Employer Name"
                        value="Employer Name"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.client_paid_amount}
                        name="headers[client_paid_amount]"
                        label="Amount Owed to Client (65%)"
                        value="Amount Owed to Client (65%)"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.employer_state_code}
                        name="headers[employer_state_code]"
                        label="Employer State"
                        value="Employer State"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.eaze_paid_amount}
                        name="headers[eaze_paid_amount]"
                        label="Eaze Medical Payment Amount (35%)"
                        value="Eaze Medical Payment Amount (35%)"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.date_of_injury}
                        name="headers[date_of_injury]"
                        label="Date Of Injury"
                        value="Date Of Injury"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.client_paid_date}
                        name="headers[client_paid_date]"
                        label="Date Client Paid"
                        value="Date Client Paid"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.icd10_code}
                        name="headers[icd10_code]"
                        label="CD10 Code"
                        value="CD10 Code"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.notes}
                        name="headers[notes]"
                        label="Notes"
                        value="Notes"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.denial_reason}
                        name="headers[denial_reason]"
                        label="Denial Reason"
                        value="Denial Reason"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.processor}
                        name="headers[processor]"
                        label="Eaze Medical Solutions Rep"
                        value="Eaze Medical Solutions Rep"
                      />
                    </Col>
                    <Col md={8}>
                      <CheckBoxField
                        checked={!!values.headers.qa_flag}
                        name="headers[qa_flag]"
                        label="QA Flag"
                        value="QA Flag"
                      />
                    </Col>
                    <Col md={6}>
                      <CheckBoxField
                        checked={!!values.headers.qa_selection_date}
                        name="headers[qa_selection_date]"
                        label="QA Flag Selection Date"
                        value="QA Flag Selection Date"
                      />
                    </Col>

                  </Row>

                  <div className="mt-3">
                    <LoadingButton
                      title={`Download Report`}
                      isLoading={isSubmitting}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>

        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            className="mt-3"
            onClick={() => setToggle(!toggle)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
