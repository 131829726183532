import React from "react";
import { Field, FieldProps } from "formik";
import { Input } from "reactstrap";

interface TextFieldProps {
  label?: string;
  name: string;
  type: any;
  placeholder?: string;
  hidden?: boolean;
  value?: any;
  required?: boolean;
  tabIndex?: number;
  phoneMask?: boolean;
  onChange?: any;
  disable?: boolean
}
const TextField: React.FC<TextFieldProps> = ({
  label,
  name,
  type,
  placeholder,
  hidden,
  value,
  required,
  tabIndex,
  phoneMask,
  onChange,
  disable
}) => {
  const filterText = (value: any) => {
    if (phoneMask) {
      const digitsOnly = value.replace(/\D/g, "");
      const trimmedDigits = digitsOnly.substr(0, 10);
      let formattedPhoneNumber = trimmedDigits.replace(
        /^(\d{3})(\d)/g,
        "($1) $2"
      );
      formattedPhoneNumber = formattedPhoneNumber.replace(
        /(\d{3})(\d)/,
        "$1 $2"
      );
      return formattedPhoneNumber;
    }
    return value;
  };

  return (
    <Field name={name}>
      {({
        field,
        form: { touched, errors, setFieldValue },
        meta,
      }: FieldProps) => (
        <div className={!!label ? "mt-3" : ""}>
          {!!label && (
            <label className="text-start mb-1 d-flex justify-content-start">
              {label}{" "}
              {required ? <span className="text-danger mx-2"> * </span> : ""}
            </label>
          )}
          <Input
            {...field}
            tabIndex={tabIndex}
            className="form-control"
            type={type}
            placeholder={placeholder}
            value={value ? value : field.value || ""}
            onChange={(e: any) => {
              setFieldValue(name, filterText(e.target.value));
              if (!!onChange) {
                onChange(e.target.value);
              }
            }}
            hidden={hidden}
            disabled={disable}
          />
          {(meta.touched || meta.error) && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default TextField;
