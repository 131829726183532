import { Field, FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { client } from "../../utils/useRequest";

interface SelectTableProps {
  label: string;
  name: string;
  isMulti?: boolean;
  table: string;
  value?: any;
  modelType?: string;
  required?: boolean;
  tabIndex?: number;
  isClearable?: any;
  onChange?: any;
}
const SelectTable: React.FC<SelectTableProps> = ({
  label,
  name,
  isMulti,
  table,
  value,
  modelType,
  required,
  tabIndex,
  isClearable,
  onChange
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([] as any);
  const [query, setQuery] = useState("");
  const [id, setId] = useState(value ?? null);
  const [type, setType] = useState(modelType ?? null);
  const fetchTable = async () => {
    setIsLoading(true);
    const response = await client(
      `api/table?search=${query}&table=${table}&id=${id}&type=${type}`
    );
    setData(response.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    // if (!!query) {
    fetchTable();
    // }
  }, [query]);
  return (
    <Field name={name}>
      {({
        field,
        form: { touched, errors, setFieldValue },
        meta,
      }: FieldProps) => (
        <div className={!!label ? "mt-3 " : ""}>
          {!!label && (
            <label className="text-start mb-1 d-flex justify-content-start">
              {label}{" "}
              {required ? <h5 className="text-danger mx-2"> * </h5> : ""}
            </label>
          )}
          <Select
            {...field}
            tabIndex={tabIndex}
            isLoading={isLoading}
            options={data}
            isSearchable
            placeholder={`Select ${label}`}
            onInputChange={(value: any) => {
              setId(null);
              setQuery(value);
            }}
            value={data?.find((item: any) => item?.value == field?.value) || ""}
            onChange={(option: any) => {
              setFieldValue(name, option?.value ?? null);
              setId(option.value);
              onChange(option.value)
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            isClearable
          />
          {(meta.touched || meta.error) && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default SelectTable;
