import React from 'react'
import Select from "react-select";

interface CustomFilterProps {
    options: any
    onChange: any
}
const CustomFilter:React.FC<CustomFilterProps> = ({options, onChange}) => {
  return (
    <Select
           
            options={options}
            isSearchable
            placeholder={`Filter By`}
            onChange={onChange}
          />
  )
}

export default CustomFilter