import { Field, FieldProps } from "formik";
import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, Input } from "reactstrap";
import { client } from "../../utils/useRequest";

interface SelectTextFieldProps {
  label: string;
  name: string;
  isMulti?: boolean;
  table: string;
  value?: any;
  modelType?: string;
  required?: boolean;
  onChange?: any;
  tabIndex?: number;
}
const SelectTextField: React.FC<SelectTextFieldProps> = ({
  label,
  name,
  table,
  value,
  required,
  onChange,
  tabIndex,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([] as any);
  const [query, setQuery] = useState(value ?? "");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const fetchTable = async () => {
    setIsLoading(true);
    const response = await client(`api/table?search=${query}&table=${table}`);

    setData([...response.data.data]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!!query) {
      fetchTable();
    }
  }, [query]);

  const toggleDropdown = () => {
    // setDropdownOpen(!dropdownOpen);
  };

  const onBlur = () => {
    setDropdownOpen(false);
  };

  return (
    <Field name={name}>
      {({
        field,
        form: { touched, errors, setFieldValue },
        meta,
      }: FieldProps) => (
        <div className={!!label ? "mt-3 " : ""}>
          {!!label && (
            <label className="text-start mb-1 d-flex justify-content-start">
              {label}{" "}
              {required ? <h5 className="text-danger mx-2"> * </h5> : ""}
            </label>
          )}
          <Input
            tabIndex={tabIndex}
            type="text"
            placeholder="Search"
            value={field?.value ?? ""}
            onChange={(e: any) => {
              setQuery(e.target.value);
              setFieldValue(name, e.target.value);
            }}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setTimeout(onBlur, 200)}
          />
          {data.length > 0 && (
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownMenu style={{ zIndex: 999999 }} tag={"div"}>
                {data.map((item: any, index: number) => {
                  return (
                    <DropdownItem
                      onClick={() => {
                        setFieldValue(name, item.value);
                        onChange(item);
                      }}
                      key={index}
                    >
                      {item.value}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          )}
          {(meta.touched || meta.error) && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default SelectTextField;
