import { GridColDef } from "@mui/x-data-grid";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import DatatableFilter from "../../component/datatable/DatatableFilter";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";

const RuralZipCodes = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li onClick={() => navigate(`/${role}/physicians/${params.id}/edit`)}>
            <a className="dropdown-item">Edit Physician</a>
          </li>
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    { field: "state_code", headerName: "State", width: 130 },
    { field: "rural_zip_code", headerName: "Rural Zip Code", width: 130 },
    { field: "year_qtr", headerName: "Year", width: 130 },
  ];

  let url = "/api/rural-zip-code";
  return (
    <DatatableFilter
      heading="Rural Zip Codes"
      columns={columns}
      url={url}
      extraButton={<UploadButton />}
    />
  );
};

export default RuralZipCodes;

const UploadButton = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Button size="sm" color="primary" onClick={() => setToggle(!toggle)}>
        Upload Rural Zip Code
      </Button>
      <Modal isOpen={toggle}>
        <ModalHeader>Upload Rural Zip Code</ModalHeader>
        <Formik initialValues={{}} onSubmit={console.log}>
          {({ handleSubmit, isSubmitting }) => {
            return (
              <>
                <ModalBody>
                  <Form onSubmit={handleSubmit}>
                    <TextField
                      label="Choose Rural Zip Code"
                      name="claim_document"
                      type="file"
                      placeholder="Select Rural Zip Code File"
                    />
                  </Form>
                  <Col className="d-flex justify-content-between">
                    <LoadingButton title={"Submit"} isLoading={isSubmitting} />
                    <Button
                      type="button"
                      color="secondary"
                      className="mt-3"
                      onClick={() => setToggle(!toggle)}
                    >
                      Cancel
                    </Button>
                  </Col>
                </ModalBody>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
