import {configureStore} from '@reduxjs/toolkit';
import authReducer from './slice/authSlice'
import userReducer from './slice/userSlice';
import roleReducer from './slice/roleSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';

const persistConfig = {
    key: "eaze",
    version: 1,
    storage
}

const reducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    roles: roleReducer
})

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;