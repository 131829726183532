import { Pagination } from "@mui/material";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Input, Row } from "reactstrap";
import useRequest, { client } from "../../utils/useRequest";
import Layout from "../layout/Layout";
import Select from "react-select";
import _ from "lodash";
import SelectFilterTable from "./SelectFilterTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Split from "react-split";
import CustomFilter from "./CustomFilter";
interface DatatableFilterProp {
  columns: GridColDef[];
  url: string;
  heading: string;
  filter?: any;
  filterMode?: any;
  addButton?: boolean;
  split?: boolean;
  splitComponent?: any;
  extraButton?: any;
  checkBoxSelection?: boolean;
  addExtra?: any;
  defaultFilter?: any;
  rowCondition?: any;
  extraSearch?: any;
  rowHeight?: any;
  checkedRow?: any;
  checkBoxAction?: any;
  mutate?: any;
  columnReorder?: any;
}

const DatatableFilter: React.FC<DatatableFilterProp> = ({
  columns,
  url,
  heading,
  filter,
  filterMode,
  addButton,
  split,
  splitComponent,
  extraButton,
  checkBoxSelection,
  addExtra,
  defaultFilter,
  rowCondition,
  extraSearch,
  rowHeight = 'auto',
  mutate,
  checkBoxAction,
  checkedRow,
  columnReorder
}) => {
  const initialQuery = {
    page: 1,
    search: "",
    sort: localStorage.getItem(url + 'sort') ?? '-id',
  } as any;
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  let filterCache = localStorage.getItem(url + "column");
  const [endpoint, setEndpoint] = useState(initialQuery);
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const [sortQuery, setSortQuery] = useState([
    {
      field: localStorage.getItem(url + 'sort')?.replace("-", ""),
      sort: localStorage.getItem(url + 'sort')?.includes("-") ? 'desc' : 'asc',
    },
  ] as any);
  const [sorting, setSorting] = useState({} as any);
  const [paging, setPaging] = useState("" as any);

  const [tableLoading, setTableLoading] = useState(false);
  const [filterBy, setFilterBy] = useState(
    !!filter && filterMode === "bucket"
      ? (Object.entries(filter ?? [])
        .map((item: any) => {
          return {
            value: item[1].filterBy,
            label: _.startCase(item[0]),
            bucket: item[1]?.bucket,
            table: item[1].table,
          };
        })
        .filter((item: any) => !!item?.bucket) as any)
      : (Object.entries(filter ?? []).map((item: any) => {
        return {
          value: item[1].filterBy,
          label: _.startCase(item[0]),
          bucket: item[1]?.bucket,
          table: item[1].table,
        };
      }) as any)
  );

  const [filtration, setFiltration] = useState({} as any);
  const [filtrationMode, setFiltrationMode] = useState(
    defaultFilter?.mode as any
  );
  let bucketStorage = localStorage.getItem(url + "bucket-filter");
  const [bucketFiltration, setBucketFiltration] = useState(
    !!bucketStorage
      ? JSON.parse(bucketStorage ?? "") ?? ([] as any)
      : ([] as any)
  );
  const [filterOption, setFilterOption] = useState([] as any);
  const [filterModel, setFilterModel] = useState(
    !!filterCache ? JSON.parse(filterCache ?? "") ?? ([] as any) : ([] as any)
  );
  const [searchBy, setSearchBy] = useState({} as any);
  const [searchByValue, setSearchByValue] = useState(null as any);
  const fetchApi = async (point: any, nextSearch = null) => {
    setTableLoading(true);
    const filterOption = filterByOption();
    const response = await client(
      url +
      "?" +
      new URLSearchParams({
        ...point,
        search: point.search,
      }) +
      filterOption +
      `&${searchBy.value}=${nextSearch ?? ''}`
    );
    setData(response.data.data ?? []);
    setIsLoading(false);
    setTableLoading(false);
  };

  const colManage = columns.reduce((obj: any, curr: any) => {
    obj[curr.field] = true;
    return obj;
  }, {});

  let columnViewCache = localStorage.getItem(url + "column-view");

  const [columView, setColumnView] = useState(
    !!columnViewCache ? JSON.parse(columnViewCache ?? "") : (colManage as any)
  );

  const [filterData, setFilterData] = useState({ items: [] } as any)
  useEffect(() => {
    fetchApi(endpoint);
    changeInFilterMode();
  }, [
    sorting,
    paging,
    filtration,
    filtrationMode,
    bucketFiltration,
    filterModel,
    mutate
  ]);

  useEffect(() => {
    resetOptions();
  }, [filtrationMode]);

  const changeInFilterMode = () => {
    let dropDownptions = Object.entries(filter ?? []).map((item: any) => {
      return {
        value: item[1].filterBy,
        label: _.startCase(item[0]),
        bucket: item[1]?.bucket,
      };
    });

    if (filtrationMode === "dropdown") {
      setFilterOption([...dropDownptions]);
    } else if (filtrationMode === "bucket") {
      dropDownptions = dropDownptions.filter((item: any) => !!item?.bucket);
      setFilterOption([...dropDownptions]);
    }
    setEndpoint({ ...endpoint, search: "" });
  };

  // const defaultFiltration = () => {
  //   let dropDownptions = Object.entries(filter ?? []).map((item: any) => {
  //     return {
  //       value: item[1].filterBy,
  //       label: _.startCase(item[0]),
  //       bucket: item[1]?.bucket,
  //     };
  //   });

  //   if (filtrationMode === "bucket") {
  //     dropDownptions = dropDownptions.filter((item: any) => !!item?.bucket);
  //   }

  //   return [...dropDownptions];
  // };

  const resetOptions = () => {
    setFiltration({});
  };

  const handleMultipleSortModelChange = (sortModel: GridSortModel) => {
    setSortQuery(sortModel);
    const { sort, ...rest } = endpoint
    console.log('sort', sort)
    if (!sort) {
      setEndpoint({ ...endpoint, sort: "id" });
    } else {
      const direction = sortModel[0]?.sort === "desc" ? "-" : "";
      const oldValue = sort.split(',').filter((item: any) => !item.includes(sortModel[0]?.field)).join(',')

      let newSort = !!direction ? direction + sortModel[0]?.field : "";

      if (!!newSort) {
        if (!!oldValue) {
          newSort = oldValue + "," + newSort
        }
      } else {
        newSort = oldValue
      }

      setEndpoint({
        ...endpoint,
        sort: newSort,
      });
    }
    setSorting(sortModel);
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setSortQuery(sortModel);
    if (sortModel.length == 0) {
      setEndpoint({ ...endpoint, sort: "id" });
    } else {
      const direction = sortModel[0]?.sort === "desc" ? "-" : "";
      const sort = direction + sortModel[0]?.field ?? "";
      localStorage.setItem(url + 'sort', sort)
      setEndpoint({
        ...endpoint,
        sort: !!sort ? sort : "",
      });
    }
    setSorting(sortModel);
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setEndpoint({ ...endpoint, page: value });
    setPaging(value);
  };

  const filterByOption = () => {
    if (!filter) return "";
    let filterObject = "";
    if (filtrationMode === "dropdown") {
      Object.entries(filtration).map((item: any) => {
        if (!!filterBy.find((ite: any) => ite.value === item[1].name)) {
          filterObject += `&filter[${item[1].name}]=${item[1].value}`;
        }
      });
    } else {
      Object.entries(bucketFiltration).map((item: any) => {
        if ((item[1] || []).length > 0) {
          filterObject += `&bucketfilter[${item[0]}]=[${item[1].toString()}]`;
        }
      });
    }

    filterModel.forEach((item: any) => {
      filterObject += `&columnFilter[${item.columnField}]=${item.value}`;
    });

    return filterObject;
  };

  const onFilterModelChange = (model: GridFilterModel) => {
    //logic is too complicated
    const newFilterModel = filterModel.filter(
      (item: any) => item.columnField != model.items[0].columnField
    );
    if (!!model.items[0].value) {
      setFilterData(model)
      setFilterModel([...newFilterModel, ...model.items]);
      localStorage.setItem(
        url + "column",
        JSON.stringify([...newFilterModel, ...model.items])
      );
    } else if (!model.items[0].value) {
      const oldFilter = filterModel.find((item: any) => item.columnField === model.items[0].columnField);
      if (!_.isEmpty(oldFilter)) {
        setFilterData({ items: [oldFilter] })
        setFilterModel(filterModel);
        localStorage.setItem(url + "column", JSON.stringify(filterModel));
      } else {
        setFilterData(model)
        setFilterModel(newFilterModel);
        localStorage.setItem(url + "column", JSON.stringify(newFilterModel));
      }

    }
  };

  const TopButton = () => {
    return (
      <>
        {addButton && (
          <Button
            className={"btn-small"}
            color="primary"
            onClick={() => navigate(`/${role}/${_.lowerCase(heading)}/create`)}
          >
            Add {heading}
          </Button>
        )}
        {addExtra}
      </>
    );
  };

  const filterModeOption = filterMode?.map((item: any) => {
    return {
      label: `${_.startCase(item)} Mode`,
      value: item,
    };
  });

  const changeBucketFilter = (values: any) => {
    setBucketFiltration({ ...bucketFiltration, ...values });
    localStorage.setItem(
      url + "bucket-filter",
      JSON.stringify({ ...bucketFiltration, ...values })
    );
  };

  const filterText = (value: any, type = 'text') => {
    if (type === 'phone') {
      const digitsOnly = value.replace(/\D/g, "");
      const trimmedDigits = digitsOnly.substr(0, 10);
      let formattedPhoneNumber = trimmedDigits.replace(
        /^(\d{3})(\d)/g,
        "($1) $2"
      );
      formattedPhoneNumber = formattedPhoneNumber.replace(
        /(\d{3})(\d)/,
        "$1 $2"
      );
      return formattedPhoneNumber;
    }
    return value;
  };

  return (
    <Layout isLoading={isLoading} heading={heading} AddButton={<TopButton />}>
      {(!!filter || !!extraButton) && (
        <div className="card">
          <div className="card-header">
            <Row>
              {!!filter && (
                <>
                  <Col md={3}>
                    <label htmlFor="" className="text-start">
                      Filter Mode
                    </label>
                    <Select
                      className="basic-single"
                      name="filter-mode"
                      options={filterModeOption}
                      placeholder="Select Mode"
                      onChange={(option: any) =>
                        setFiltrationMode(option.value)
                      }
                      value={filterModeOption?.find(
                        (item: any) => item.value === filtrationMode
                      )}
                    />
                  </Col>
                  <Col md={4}>
                    <label htmlFor="" className="text-start">
                      Filter By
                    </label>
                    <Select
                      options={filterOption}
                      onChange={(a: any) => {
                        setFilterBy(a);
                        let newfiltration = {};
                        let oldFiltration = filtration;
                        Object.entries(oldFiltration).map((item: any) => {
                          if (
                            !!filterBy.find((ite: any) => ite.value === item[0])
                          ) {
                            newfiltration = {
                              ...newfiltration,
                              [item[0]]: item[1],
                            };
                          }
                        });
                        setFiltration(newfiltration);
                      }}
                      value={filterBy}
                      isMulti
                      placeholder="Filter by"
                    />
                  </Col>
                </>
              )}
              {!!extraButton && <Col md={3}>{extraButton}</Col>}
            </Row>
          </div>
          <div className="card-body filter-card-body">
            <div>
              <Row>
                {filtrationMode === "dropdown" &&
                  Object.entries(filter).map((item: any, index: number) => {
                    if (
                      filterBy &&
                      filterBy.length > 0 &&
                      !!filterBy.find(
                        (find: any) => find.value === item[1].filterBy
                      )
                    ) {
                      return (
                        <Col key={index} md={3}>
                          <label htmlFor="" className="text-start">
                            {_.startCase(item[1].name)}
                          </label>
                          {item[1].type === "table" && (
                            <SelectFilterTable
                              filter={item}
                              selected={(value: any) => {
                                setFiltration({
                                  ...filtration,
                                  [item[1].filterBy]: value,
                                });
                              }}
                            />
                          )}
                          {item[1].type === "text" && (
                            <Input
                              placeholder={`Enter ${_.startCase(item[0])}`}
                              onChange={(e: any) => {
                                setFiltration({
                                  ...filtration,
                                  [item[1].filterBy]: {
                                    name: item[1].filterBy,
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                          )}
                        </Col>
                      );
                    }
                  })}
                {filtrationMode === "bucket" && (
                  <BucketMode
                    setIsLoading={(value: any) => setIsLoading(value)}
                    filter={filter}
                    filterBy={filterBy}
                    table={_.lowerCase(heading)}
                    changeFilter={changeBucketFilter}
                    url={url}
                    bucketFilter={bucketFiltration}
                  />
                )}
              </Row>
            </div>
          </div>
        </div>
      )}
      <Split
        sizes={[!!split ? 60 : 100, !!split ? 40 : 0]}
        minSize={split ? 300 : 0}
        expandToMin={false}
        gutterSize={!!split ? 10 : 0}
        gutterAlign="center"
        snapOffset={1}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        className="d-flex justify-content-between"
      >
        <div
          style={{
            height: "80vh",
            paddingBottom: 150,
            width: "calc(100% - 0px) !important",
            position: 'relative'
          }}
        >
          <Row>
            <Col className="d-flex justify-content-between" md={3}>
              {checkBoxAction}
              <input
                type="text"
                value={endpoint.search ?? ""}
                placeholder="Search"
                className="form-control  mb-2"
                onChange={(e) =>
                  setEndpoint({
                    ...endpoint,
                    page: 1,
                    search: e.target.value,
                  })
                }
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    fetchApi(endpoint);
                  }
                }}
              />
              <i
                className="fa fa-times mt-2 mx-3 cursor-pointer"
                onClick={() => {
                  setEndpoint({ ...endpoint, search: "" });
                  fetchApi({ ...endpoint, search: "" });
                }}
              ></i>
              
            </Col>
            {/* <Col className="d-flex" md={3}> */}
              <Col className="reorderColumn">{columnReorder}</Col>
            {/* </Col> */}
            <Col md={1}>
              {Object.values(columView).filter((value) => value === true)
                .length === 0 && (
                  <Col>
                    <Badge
                      className="cursor-pointer"
                      color="info"
                      onClick={() => {
                        setColumnView(colManage);
                        localStorage.setItem(
                          url + "column-view",
                          JSON.stringify(colManage)
                        );
                      }}
                    >
                      Show All Column
                    </Badge>
                  </Col>
                )}
            </Col>
            {!!extraSearch && (
              <Col md={5}>
                <Row>
                  <Col md={6}>
                    <CustomFilter
                      onChange={(val: any) => {
                        setSearchBy(val);
                        setSearchByValue(null)
                        fetchApi(endpoint)
                      }}
                      options={extraSearch.map((item: any) => {
                        return {
                          ...item,
                          label: item.title,
                          value: item.name,
                        };
                      })}
                    />
                  </Col>
                  <Col className="d-flex justify-content-between" md={6}>
                    <input
                      type={searchBy.type}
                      value={searchByValue ?? ""}
                      placeholder="Search"
                      className="form-control  mb-2"
                      onChange={(e) => setSearchByValue(filterText(e.target.value, searchBy.type))}
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                          fetchApi(endpoint, searchByValue);
                        }
                      }}
                    />
                    <i
                      className="fa fa-times mt-2 mx-3 cursor-pointer"
                      onClick={() => {
                        setSearchByValue(null);
                        fetchApi(endpoint, null);
                      }}
                    ></i>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Row className="workAndFlag">
          {url === "/api/claims" && <Col className="d-inline mx-2 d-flex">
            <Input type="checkbox" onChange={(e) => {
                if (e.target.checked) {
                  setEndpoint({...endpoint, recentWork: "recent_work" })
                  fetchApi({ ...endpoint, recentWork: "recent_work" })
                } else {
                  setEndpoint({...endpoint, recentWork: "" })
                  fetchApi({...endpoint, recentWork: "" })
                }
              }} /> &nbsp; Recent Work &nbsp;
              <Input type="checkbox" onChange={(e) => {
                if (e.target.checked) {
                  setEndpoint({...endpoint, myQaFlag: "my_qa_flag" })
                  fetchApi({ ...endpoint, myQaFlag: "my_qa_flag" })
                } else {
                  setEndpoint({...endpoint, myQaFlag: "" })
                  fetchApi({...endpoint, myQaFlag: "" })
                }
              }} /> &nbsp; My QA Flag
              </Col>}
          </Row>
          <Row>
            <div className="d-flex justify-content-start mb-2">
              {filterModel.map((item: any, index: number) => {
                return (
                  <Button
                    key={index}
                    color="danger"
                    className="mt-2 mx-1"
                    outline
                    size="sm"
                    onClick={() => {
                      setFilterData({ items: [] })
                      const newFilter = filterModel.filter((i: any) => {
                        return i.columnField != item.columnField;
                      });
                      setFilterModel(newFilter);
                      localStorage.setItem(
                        url + "column",
                        JSON.stringify(newFilter)
                      );
                    }}
                  >
                    {`${_.startCase(item.columnField)}: ${item.value}`}
                    <i className=" mx-1 fa fa-times"></i>
                  </Button>
                );
              })}
            </div>
          </Row>
          <DataGrid
            getRowClassName={rowCondition}
            rows={data?.data}
            columns={columns}
            sortModel={sortQuery}
            rowsPerPageOptions={[]}
            sortingMode="server"
            hideFooter
            checkboxSelection={checkBoxSelection}
            onSortModelChange={handleSortModelChange}
            loading={tableLoading}
            getRowHeight={() => rowHeight}
            onColumnVisibilityModelChange={(item: any) => {
              if (_.isEmpty(item)) {
                setColumnView(colManage);
                localStorage.setItem(
                  url + "column-view",
                  JSON.stringify(colManage)
                );
              } else {
                setColumnView({ ...columView, ...item });
                localStorage.setItem(
                  url + "column-view",
                  JSON.stringify({ ...columView, ...item })
                );
              }
            }}
            columnVisibilityModel={columView}
            filterMode={"server"}
            onFilterModelChange={onFilterModelChange}
            filterModel={filterData}
            disableSelectionOnClick
            onSelectionModelChange={(row: any) => {
              if (checkBoxSelection) {
                checkedRow(row);
              }
            }}
          />
          <div className="mt-2">
            Showing {data?.from} to {data?.to} of{" "}
            {data?.total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
            entries
          </div>
          <div className="d-flex justify-content-center m-1">
            <Pagination
              count={data?.last_page}
              variant="outlined"
              color="primary"
              page={endpoint.page}
              onChange={onChangePage}
            />
          </div>
        </div>
        <div style={{ display: !!split ? "" : "none" }}>
          {!!split && splitComponent}
        </div>
      </Split>
    </Layout>
  );
};

export default DatatableFilter;

interface BucketModeProps {
  filter: any;
  filterBy: any;
  table: string;
  changeFilter: any;
  setIsLoading: any;
  url: string;
  bucketFilter: any;
}
const BucketMode: React.FC<BucketModeProps> = ({
  filter,
  filterBy,
  table,
  changeFilter,
  setIsLoading,
  url,
  bucketFilter,
}) => {
  const [filterOption, setFilterOption] = useState({} as any);

  const fetchFilter = async () => {
    setIsLoading(true);
    let newFilter = {} as any;
    if (!!filterBy && filterBy.length > 0) {
      filterBy.forEach(async (item: any) => {
        const response = await client.get(
          `/api/filter-count?table=${item.table}&count=${table}`
        );
        newFilter = { ...newFilter, [item.value]: response.data?.data ?? [] };
        setFilterOption(newFilter);
      });
    } else {
      setFilterOption({});
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchFilter();
  }, [filterBy]);

  if (!!filterOption && filterOption.length === 0) {
    return <></>;
  }
  return (
    <>
      {Object.entries(filterOption).map((item: any, index: number) => {
        return (
          <FilterBucket
            url={url}
            filterName={Object.keys(filter)[index]}
            item={item}
            key={index}
            changeFilter={changeFilter}
            bucketFilter={bucketFilter}
          />
        );
      })}
    </>
  );
};

interface FilterBucketProps {
  item: any;
  changeFilter: any;
  filterName: any;
  url: string;
  bucketFilter: any;
}
const FilterBucket: React.FC<FilterBucketProps> = ({
  item,
  changeFilter,
  filterName,
  url,
  bucketFilter,
}) => {
  const [filter, setFilter] = useState(
    bucketFilter[`${item[0]}`] ?? ([] as any[])
  );
  // const [initialLoad, setInitialLoad] = useState(true);
  const [filterLoad, setFilterLoad] = useState({
    [`${item[0]}`]: bucketFilter[`${item[0]}`],
  } as any);
  // console.log('newMOde', bucketFilter[`${item[0]}`], )
  // console.log(filter, filterLoad)
  useEffect(() => {
    // if (initialLoad) {
    //   setInitialLoad(false);
    //   if (!!stor) {
    //     changeFilter(JSON.parse(stor ?? ""));
    //   }
    // } else {
    changeFilter({ [item[0]]: filter });
    // }
  }, [filter]);

  const filterAdd = (value: any) => {
    let newFilter = filter;
    if ((filter || []).includes(value)) {
      newFilter.splice(newFilter.indexOf(value), 1);
      setFilter([...newFilter]);
      setFilterLoad({ ...filterLoad, [item[0]]: [...newFilter] });
      // localStorage.setItem(
      //   url,
      //   JSON.stringify({ ...filterLoad, [item[0]]: [...newFilter] })
      // );
    } else {
      setFilter([...filter, value]);
      setFilterLoad({ ...filterLoad, [item[0]]: [...filter, value] });
      // localStorage.setItem(
      //   url,
      //   JSON.stringify({ ...filterLoad, [item[0]]: [...filter, value] })
      // );
    }
  };

  return (
    <Row className={"mb-2"}>
      <label className="my-1" htmlFor="">
        {_.startCase(filterName)} &nbsp;{" "}
        <Badge
          className="cursor-pointer"
          size="sm"
          color="danger"
          onClick={() => {
            setFilter([]);
            setFilterLoad({ [item[0]]: [] });
          }}
        >
          clear
        </Badge>
      </label>
      <Col md={12}>
        {item[1].map((it: any, id: number) => {
          return (
            <FilterButton
              filter={filterLoad[`${item[0]}`]}
              item={it}
              type={item[0]}
              key={id}
              onClickButton={filterAdd}
            />
          );
        })}
      </Col>
    </Row>
  );
};

interface FilterButtonProps {
  item: any;
  onClickButton: any;
  type: any;
  filter: any;
}
const FilterButton: React.FC<FilterButtonProps> = ({
  item,
  onClickButton,
  type,
  filter,
}) => {
  const [active, setActive] = useState(filter?.includes(item.id));

  useEffect(() => {
    setActive(filter?.includes(item.id));
  }, [filter]);

  return (
    <Button
      color={`${active ? "primary" : "secondary"}`}
      size="sm"
      className={`mx-1 my-1`}
      onClick={() => {
        setActive(!active);
        onClickButton(item.id);
      }}
    >
      {item.name}
      {item?.second_count > 0 && (
        <Badge className="mx-1" color="warning" pill>
          {item.second_count}
        </Badge>
      )}
      <Badge className="mx-1" color="primary" pill>
        {item.count}
      </Badge>
    </Button>
  );
};
