import { GridColDef } from "@mui/x-data-grid";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import { Button, Input } from "reactstrap";
import Datatable from "../../component/datatable/Datatable";
import LoadingButton from "../../component/form-component/LoadingButton";
import { client, downloadBlobFile } from "../../utils/useRequest";
import toastr from "toastr";

interface ViewCsvProps {
  closeSplit: any;
  id: number;
}
const ViewCsv: React.FC<ViewCsvProps> = ({ closeSplit, id }) => {
  const loadValue = (row: any) => {
    return <CsvInput row={row} />;
  };
  const [mutate, setMutate] = useState({} as any);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "segment", headerName: "Segment", width: 90 },
    { field: "key", headerName: "Label", width: 100 },
    {
      field: "value",
      headerName: "Value",
      sortable: false,
      flex: 1,
      renderCell: loadValue,
    },
  ];

  let url = `/api/claims/${id}/csv-view`;

  useEffect(() => {}, [id]);

  const generateCsv = async () => {
    const response = await client.get(`api/claim/generate/${id}`);
    if (response.data.status) {
      setMutate(response.data.data);
      toastr.success(response?.data.message);
    }
  };

  const exportCsv = async () => {
    const url = `/api/claim/export/${id}`;
    const fileName = "Claim.csv";
    await downloadBlobFile(url, fileName);
  };

  const ExtraComponent = () => {
    return (
      <>
        <Button color="primary" outline onClick={() => generateCsv()}>
          Generate CSV
        </Button>
        <Button
          color="success"
          outline
          onClick={() => exportCsv()}
          className="mx-2"
        >
          Export CSV
        </Button>
      </>
    );
  };

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <h3>Claim CSV View</h3>
        <i
          style={{ cursor: "pointer" }}
          className="fa fa-times"
          onClick={closeSplit}
        ></i>
      </div>
      <div className="card-body">
        <Datatable
          extraComponent={<ExtraComponent />}
          columns={columns}
          url={url}
          mutate={mutate}
        />
      </div>
    </div>
  );
};

export default ViewCsv;

interface CsvInputProps {
  row: any;
}

const CsvInput: React.FC<CsvInputProps> = ({ row }) => {
  const [newValue, setNewValue] = useState(row.row.value);
  const [isDisable, setIsDisabled] = useState(true);
  const saveNewValue = async () => {
    const response = await client.put(`/api/update-csv/${row.id}`, {
      value: newValue,
    });
    if (response.data.status) {
      toastr.success(response?.data.message);
      setIsDisabled(true);
    }
  };

  return (
    <>
      <Input
        type="text"
        onChange={(e) => {
          setNewValue(e.target.value);
          setIsDisabled(false);
        }}
        value={newValue}
      />
      <Button
        color={!isDisable ? "primary" : "secondary"}
        disabled={isDisable}
        className="mx-2"
        size="sm"
        onClick={saveNewValue}
      >
        <i className="fa fa-save"></i>
      </Button>
    </>
  );
};
