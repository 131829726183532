import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";

const Fitters = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li
            onClick={() => navigate(`/${role}/manufacturers/${params.id}/edit`)}
          >
            <a className="dropdown-item">Edit Sub Category</a>
          </li>
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 70,
    },
    { field: "id", headerName: "ID", width: 70 },
    { field: "fitter_name", headerName: "Name" },
    { field: "fitter_city", headerName: "City" },
    { field: "fitter_state", headerName: "State" },
    { field: "fitter_phone", headerName: "Phone" },
    { field: "fitter_fax", headerName: "Fax" },
    { field: "fitter_email", headerName: "Email" },
  ];

  let url = "/api/manufacturers/fitters";
  return (
    <DatatableFilter
      heading="Fitters"
      columns={columns}
      url={url}
      addButton={true}
    />
  );
};

export default Fitters;
