import React from "react";
import { useDispatch } from "react-redux";
import { client } from "../../utils/useRequest";
import { logout } from "../../store/slice/authSlice";
import storage from "redux-persist/lib/storage";
import { roleFail } from "../../store/slice/roleSlice";
import { useSelector } from "react-redux";

interface NavbarProps {
  setToggle?: any;
  mode: boolean;
  changeMode: any;
}
const Navbar: React.FC<NavbarProps> = ({ setToggle, mode, changeMode }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user);

  const logoutSession = async () => {
    try {
      const response = await client.get(`/api/logout`);
      if (response.data.status) {
        dispatch(roleFail("Sesson is logout"));
        dispatch(logout());
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <nav className="navbar navbar-expand navbar-light navbar-bg">
      <a className="sidebar-toggle js-sidebar-toggle" onClick={setToggle}>
        <i className="hamburger align-self-center "></i>
      </a>

      <div className="navbar-collapse collapse">
        <ul className="navbar-nav navbar-align">
          <li>

            <div className="mb-3 form-check nav-checkbox">
              <input type="checkbox"
                     className="form-check-input"
                     name="employer_self_administered"
                     id={`tottleflexSwitchCheckChecked`}
                     value="1"
                     checked={!!mode || false}
                     onChange={() => changeMode(!mode)}
              />
                <label className="form-check-label" htmlFor="employer_self_administered">Dark Theme</label>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-icon dropdown-toggle"
              href="#"
              id="alertsDropdown"
              data-bs-toggle="dropdown"
            >
              <div className="position-relative">
                <i className="fa fa-bell-o"></i>
                <span className="indicator">4</span>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
              aria-labelledby="alertsDropdown"
            >
              <div className="dropdown-menu-header">4 New Notifications</div>
              <div className="list-group">
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <i
                        className="text-danger"
                        data-feather="alert-circle"
                      ></i>
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Update completed</div>
                      <div className="text-muted small mt-1">
                        Restart server 12 to complete the update.
                      </div>
                      <div className="text-muted small mt-1">30m ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <i className="text-warning" data-feather="bell"></i>
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Lorem ipsum</div>
                      <div className="text-muted small mt-1">
                        Aliquam ex eros, imperdiet vulputate hendrerit et.
                      </div>
                      <div className="text-muted small mt-1">2h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <i className="text-primary" data-feather="home"></i>
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Login from 192.186.1.8</div>
                      <div className="text-muted small mt-1">5h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <i className="text-success" data-feather="user-plus"></i>
                    </div>
                    <div className="col-10">
                      <div className="text-dark">New connection</div>
                      <div className="text-muted small mt-1">
                        Christina accepted your request.
                      </div>
                      <div className="text-muted small mt-1">14h ago</div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="dropdown-menu-footer">
                <a href="#" className="text-muted">
                  Show all notifications
                </a>
              </div>
            </div>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-icon dropdown-toggle d-inline-block d-sm-none"
              href="#"
              data-bs-toggle="dropdown"
            >
              <i className="align-middle" data-feather="settings"></i>
            </a>

            <a
              className="nav-link dropdown-toggle d-none d-sm-inline-block"
              href="#"
              data-bs-toggle="dropdown"
            >
              <img
                src="/no-avatar.png"
                className="avatar img-fluid rounded me-1"
                alt={user.name}
              />
              <span className="text-dark">{user.name}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-end">
              {/*<a className="dropdown-item" href="pages-profile.html">*/}
              {/*  <i className="align-middle me-1" data-feather="user"></i>{" "}*/}
              {/*  Profile*/}
              {/*</a>*/}
              {/*<div className="dropdown-divider"></div>*/}
              {/*<a className="dropdown-item" href="index.html">*/}
              {/*  <i className="align-middle me-1" data-feather="settings"></i>{" "}*/}
              {/*  Settings & Privacy*/}
              {/*</a>*/}

              {/*<div className="dropdown-divider"></div>*/}
              <a className="dropdown-item" onClick={logoutSession}>
                Log out
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
