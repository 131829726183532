import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import { client } from "../../utils/useRequest";
import toastr from "toastr";
import EditClientComponent from "./EditClientComponent";

const AddClient = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const handleSubmit = async (values: any) => {
    console.log(values);
    // const response = await client.post("/api/clients", { ...values });
    // if (response.data.status) {
    //   toastr.success(response?.data.message);
    //   navigate(`/${role}/clients`);
    // }
  };
  return (
    <Layout>
      <div className="card py-2">
        <h3 className="mb-3">Add New Client</h3>
        <EditClientComponent
          onSubmit={handleSubmit}
          initialValue={{ mngr: [{}] }}
        />
      </div>
    </Layout>
  );
};

export default AddClient;
