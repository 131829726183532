import { GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";
import { client } from "../../utils/useRequest";
import toastr from "toastr";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { INSURANCE_DELETE } from "../../utils/Role";

const Insurance = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const [tableChanged, setTableChanged] = useState();
  const { permission } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    const onDelete = (id: number) => {

    }

    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li onClick={() => navigate(`/${role}/insurances/${params.id}/edit`)}>
            <a className="dropdown-item">Edit Insurance</a>
          </li>
          {permission.includes(INSURANCE_DELETE) &&
            <li onClick={() => onDelete(params.id)}>
              <DeleteInsurance id={params.id} setTableChanged={setTableChanged} />
            </li>
          }
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 70,
    },
    { field: "id", headerName: "Physician ID", width: 70 },
    {
      field: "insurance_company_name",
      headerName: "Insurance Company Name",
      width: 230,
    },
    { field: "insurance_phone1", headerName: "Phone Number", width: 230 },
  ];

  let url = "/api/insurances";
  return (
    <DatatableFilter
      heading="Insurances"
      columns={columns}
      url={url}
      addButton={true}
      mutate={tableChanged}
    />
  );
};

export default Insurance;

interface DeleteInsuranceProps {
  id: any;
  setTableChanged: any;
}
const DeleteInsurance: React.FC<DeleteInsuranceProps> = ({
  id,
  setTableChanged,
}) => {
  const [toggle, setToggle] = useState(false);

  const onDelete = async (value: any) => {
    const response = await client.delete(`/api/insurances/${id}`);

    if (response.status) {
      toastr.success("Insurance Deleted Successfully");
      setTableChanged(response);
    }
  };
  return (
    <div>
      <a
        className="dropdown-item"
        color="danger"
        onClick={() => setToggle(true)}
      >
        Delete
      </a>
      <Modal isOpen={toggle}>
        <ModalHeader>Delete</ModalHeader>
        <ModalBody>
          Do you want to delete this insurance?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => onDelete(!toggle)}>
            Yes
          </Button>
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

