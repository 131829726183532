import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";
import { client, uploadMedia } from "../../utils/useRequest";

interface UploadClaimDocumentProps {
  onSubmit: any;
  toggle: boolean;
  setToggle: any;
}
const UploadClaimDocument: React.FC<UploadClaimDocumentProps> = ({
  onSubmit,
  toggle,
  setToggle,
}) => {
  return (
    <>
      <Button className={'pull-right'} size="sm" color="primary" onClick={() => setToggle(!toggle)}>
        Upload Claim Document
      </Button>
      <Modal isOpen={toggle}>
        <ModalHeader>Upload Claim Document</ModalHeader>
        <Formik initialValues={{} as any} onSubmit={onSubmit}>
          {({ handleSubmit, isSubmitting, setFieldValue }) => {
            return (
              <>
                <ModalBody>
                  <Form onSubmit={handleSubmit}>
                    <div className={"mt-3"}>
                      <label className="text-start mb-1 d-flex justify-content-start">
                        Upload Claim Document
                        <h5 className="text-danger mx-2"> * </h5>
                      </label>
                      <Input
                        name="document"
                        className="form-control"
                        type={"file"}
                        placeholder={"Upload Claim Document"}
                        onChange={(e: any) =>
                          setFieldValue("document", e.currentTarget.files[0])
                        }
                      />
                    </div>
                    <Col className="d-flex justify-content-between">
                      <LoadingButton
                        title={"Upload Document"}
                        isLoading={isSubmitting}
                      />
                      <Button
                        type="button"
                        color="secondary"
                        className="mt-3"
                        onClick={() => setToggle(!toggle)}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Form>
                </ModalBody>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default UploadClaimDocument;
