import { Form, Formik } from "formik";
import React, {useState} from "react";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";
import CheckBoxField from "../../component/form-component/CheckBoxField";
import * as yup from "yup";
import {Col, Input, Row} from "reactstrap";
import Editor from "../../component/form-component/Editor";
import SelectTable from "../../component/form-component/SelectTable";
import DatePickerComponent from "../../component/form-component/DatePickerComponent";
import SelectCustom from "../../component/form-component/SelectCustom";
import ToggleButtonField from "../../component/form-component/ToggleButtonField";
import DatePicker from "react-datepicker";
import moment from "moment";
import SelectTextField from "../../component/form-component/SelectTextField";

const AddUserSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required"),
  email: yup
    .string()
    .required("Email is required"),
});

interface EditUserComponentProps {
  initialValue?: any;
  onSubmit: any;
}

const EditUserComponent: React.FC<EditUserComponentProps> = ({
  initialValue,
  onSubmit,
}) => {
  const [visible, setVisible] = useState(false);
  const [pass_visible, setPassVisible] = useState(false);

  // setVisible(false);
  // setPassVisible(false);
  return (
    <Formik
      initialValues={{ ...initialValue }}
      validationSchema={AddUserSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting,values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={3}>
                <TextField
                    tabIndex={1}
                    label="Name"
                    name="name"
                    type="text"
                    placeholder="Name"
                />
              </Col>
              <Col md={3}>
                <TextField
                    tabIndex={2}
                    label="Email"
                    name="email"
                    type="text"
                    placeholder="Email"
                />
              </Col>
              <Col md={3}>
                <DatePickerComponent
                    tabIndex={3}
                    label="Birthday"
                    name="birthday"
                />
              </Col>
              <Col md={3}>
                <DatePickerComponent
                    tabIndex={4}
                    label="Anniversary"
                    name="anniversary"
                />
              </Col>
              <Col md={3}>
                <TextField
                    tabIndex={5}
                    label="SSN"
                    name="ssn"
                    type="text"
                    placeholder="Enter SSN"
                />
              </Col>
              <Col md={3}>
                <TextField
                    tabIndex={6}
                    label="Job Title"
                    name="job_title"
                    type="text"
                    placeholder="Enter Job Title"
                />
              </Col>
              <Col md={3}>
                <TextField
                    tabIndex={7}
                    label="Gusto Employee ID"
                    name="gusto_employee_id"
                    type="text"
                    placeholder="Enter Gusto Employee ID"
                />
              </Col>
              <Col md={3}>
                <SelectCustom
                    tabIndex={8}
                    label="Department "
                    name="department_id"
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                />
              </Col>
              <Col md={3}>
                <SelectTable
                    tabIndex={1}
                    name="role[]"
                    label="Role"
                    table="roles"
                    value={values.role}
                />
              </Col>
              <Col md={3}>
                <TextField
                    tabIndex={10}
                    label="Phone"
                    name="phone"
                    type="text"
                    placeholder="(999)-999-9999"
                    phoneMask
                />
              </Col>

              <Col md={3}>
                <SelectTable
                    tabIndex={1}
                    name="client_id"
                    label="Client"
                    table="clients"
                    value={values.client_id}
                />
              </Col>

              <Col md={3}>
                <SelectCustom
                    tabIndex={12}
                    label="Status"
                    name="status"
                    options={[
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "inactive" },
                    ]}

                />
              </Col>

              <Col md={3}>
                <TextField
                  tabIndex={13}
                  label="Extension"
                  name="extension"
                  type="text"
                  placeholder="Enter Extension"
                />
              </Col>
              <Col md={4}>
                <TextField
                  tabIndex={14}
                  label="Shift From"
                  name="shift_from"
                  type="time"
                />
              </Col>
              <Col md={4}>
                <TextField
                  tabIndex={15}
                  label="Shift To"
                  name="shift_to"
                  type="time"
                />
              </Col>

              <Col md={12}>
                <ToggleButtonField
                    tabIndex={16}
                    name="overtime_available"
                    label="Overtime Available ? "
                    checked={!!values.overtime_available}
                    onChange={(e: any) => setVisible(true)}
                />   
                    
                {visible && (
                    <Row md={4}>
                      <Col md={1}>
                        <TextField
                            tabIndex={17}
                            label="Overtime"
                            name="overtime[hour]"
                            type="number"
                            placeholder='hr'
                        />
                      </Col>
                      <Col md={1}>
                        <TextField
                            tabIndex={18}
                            label=" Hrs"
                            name="overtime[minute]"
                            type="number"
                            placeholder='min'
                        />
                      </Col>
                    </Row>
                )}
              </Col>

              {values.edit !== true?
              <Row>
              <Col md={6}>
                <TextField
                    tabIndex={17}
                    label="Password"
                    name="password"
                    type="password"
                />
              </Col>
              <Col md={6}>
                <TextField
                    tabIndex={18}
                    label="Confirm Password"
                    name="password_confirmation"
                    type="password"
                />
              </Col>
              </Row>:''}
              {values.edit === true?
              <Row>
                <Col md={3} className={"mt-2"}>
                  <CheckBoxField
                      tabIndex={19}
                      checked={!!values.reset_password}
                      name="reset_password"
                      label="Reset Password"
                      onChange={(e: any) => setPassVisible(true)}
                
                  />
                </Col>
              </Row>:''}

              {pass_visible && (
                     <Row>
                     <Col md={6}>
                       <TextField
                           tabIndex={17}
                           label="Password"
                           name="password"
                           type="password"
                       />
                     </Col>
                     <Col md={6}>
                       <TextField
                           tabIndex={18}
                           label="Confirm Password"
                           name="password_confirmation"
                           type="password"
                       />
                     </Col>
                     </Row>
                )}




            <div className="mt-3">
              <LoadingButton
                title={`${!!initialValue ? "Update" : "Save"} User`}
                isLoading={isSubmitting}
              />
            </div>
            </Row>

          </Form>
        );
      }}
    </Formik>
  );
};

export default EditUserComponent;
