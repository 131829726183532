import { client } from '../../utils/useRequest'
import { roleFail, roleSuccess } from '../slice/roleSlice';


const getRoles = () => async (dispatch) => {
    try{
        const response = await getRole();
        if(!!response.status){
            return dispatch(roleSuccess(response.data.data))
        }else{
            return dispatch(roleFail('Fetching role failed'));
        }
    }catch(e){
        return dispatch(roleFail(e.message));
    }
}

export default getRoles;

const getRole = async () => {

    try{
         const response = await client.get(`/api/user`)
        return response;
    }catch(e){
        
    }
  
}