import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { client } from "../utils/useRequest";
import Datatable from "./datatable/Datatable";
import { GridColDef } from "@mui/x-data-grid";
import parse from "html-react-parser";
import {
  Badge,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Field, Form, Formik } from "formik";
import SelectTable from "./form-component/SelectTable";
import Editor from "./form-component/Editor";
import LoadingButton from "./form-component/LoadingButton";
import TextField from "./form-component/TextField";
import toastr from "toastr";
import * as yup from "yup";

interface AddNoteComponentProps {
  modelType: string;
  modelId: any;
}
const AddNoteComponent: React.FC<AddNoteComponentProps> = ({
  modelType,
  modelId,
}) => {
  const [tableChanged, setTableChanged] = useState({} as any);

  const handleAdd = async (values: any, { resetForm }: any) => {
    console.log(values);
    const response = await client.post(
      `/api/notes/${modelType}/${modelId}`,
      values,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data.status) {
      toastr.success(response?.data.message);
      setTableChanged(response);
      resetForm();
    }
  };

  const noteColumns: GridColDef[] = [
    {
      field: "added_by",
      headerName: "Added By",
      width: 250,
      renderCell: (row: any) => {
        return (
          <div className="my-5">
            <Row>
              <Col md={12}>
                <h4>{row.row.posted_by?.name}</h4>
              </Col>
              <Col className="text-muted" md={12}>
                On {row.row.note_timestamp}
              </Col>
              <Col className="mx-3" md={12}>
                <Badge>{row?.row?.note_type?.title}</Badge>
              </Col>
              <Col className="my-2" md={12}>
                <Badge
                  className="mx-3"
                  color={row.row.external === 1 ? "warning" : "primary"}
                >
                  {row.row.external === 1 ? "public" : "internal"}
                </Badge>
                <EditNote
                  onSubmit={handleAdd}
                  initialValue={row.row}
                  modelType={modelType}
                  hideModal={tableChanged}
                />
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      field: "attached_notes",
      headerName: "Notes",
      flex: 10,
      renderCell: (rows: any) => {
        return (
          <Row className="my-2">
            <div dangerouslySetInnerHTML={{ __html: rows.value }} />
          </Row>
        );
      },
    },
  ];
  return (
    <div className="card">
      <AddNote onSubmit={handleAdd} modelType={modelType} /> <br />
      <div className="card-body" style={{ height: "auto" }}>
        <Datatable
          columns={noteColumns}
          url={`/api/notes?type=${modelType}&model_id=${modelId}`}
          mutate={tableChanged}
        />
      </div>
    </div>
  );
};

export default AddNoteComponent;

interface AddNoteProps {
  onSubmit: any;
  initialValue?: any;
  modelType: string;
}

const AddNoteSchema = yup.object().shape({
  attached_notes: yup.string().required("Note is required"),
});
const AddNote: React.FC<AddNoteProps> = ({
  onSubmit,
  initialValue,
  modelType,
}) => {
  return (
    <>
      <div className="card-header">Notes</div>
      <div className="card-body">
        <Formik
          initialValues={{ ...initialValue, external: "internal" } as any}
          onSubmit={onSubmit}
          validationSchema={AddNoteSchema}
        >
          {({ handleSubmit, isSubmitting, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={3}>
                    <SelectTable
                      modelType={modelType}
                      value={values.note_type}
                      table="note_types"
                      label="Note Type"
                      name="note_type_id"
                    />
                  </Col>
                  <Col md={12}>
                    <Editor label="Notes" name="attached_notes" height="200" />
                  </Col>
                  <Col className="d-flex justify-content-start mt-3" md={12}>
                    <div role="group" aria-labelledby="my-radio-group">
                      <label>
                        <Field type="radio" name="external" value="internal" />
                        &nbsp; Internal Only
                      </label>
                      <label className="mx-3">
                        <Field type="radio" name="external" value="external" />
                        &nbsp; Public
                      </label>
                    </div>
                  </Col>
                  <Col md={3}>
                    <LoadingButton
                      title={`${!!initialValue ? "Update" : "Add"} Note`}
                      isLoading={isSubmitting}
                    />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

interface EditNoteProps {
  onSubmit: any;
  initialValue: any;
  modelType: string;
  hideModal: any;
}
const EditNote: React.FC<EditNoteProps> = ({
  onSubmit,
  initialValue,
  modelType,
  hideModal,
}) => {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (!!hideModal) {
      setToggle(false);
    }
  }, [hideModal]);

  return (
    <>
      <Button size="sm" color="primary" onClick={() => setToggle(!toggle)}>
        <i className="fa fa-edit"></i>
      </Button>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>Update Note</ModalHeader>
        <ModalBody>
          <AddNote
            onSubmit={onSubmit}
            initialValue={{
              ...initialValue,
              external: `${!!initialValue.external ? "external" : "internal"}`,
              note_id: initialValue.id,
            }}
            modelType={modelType}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setToggle(!toggle)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
