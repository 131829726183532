import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";
import { filterAmount } from "../../utils/helper";

const ClaimPaymentList = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const columns: GridColDef[] = [
    { field: "id", headerName: "Payment ID", width: 120, sortable: false },
    {
      field: "claim_id",
      headerName: "Claim Id",
      width: 100,
      renderCell: (row: any) => {
        return (
          <Link to={`/${role}/claims/${row.row.claim_id}/edit`} className="text-primary">
            B-{row.row.claim_id}
          </Link>
        );
      },
      sortable: false,
      filterable: false,
    },
    {
      field: "payment_receipt_date",
      headerName: "Receipt Date",
      width: 130,
      sortable: false,
      filterable: false,
    },
    {
      field: "client_paid_date",
      headerName: "Client Paid Date",
      width: 130,
      sortable: false,
      filterable: false,
    },
    {
      field: "payment_check_number",
      headerName: "Check Number",
      width: 130,
      sortable: false,
      filterable: false,
    },
    {
      field: "client_paid_amount",
      headerName: "Client Paid Amount",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell:(row: any) => filterAmount(row.row.client_paid_amount)
    },
    {
      field: "payment_amount",
      headerName: "Payment Amount",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell:(row: any) => filterAmount(row.row.payment_amount) 
    },
    {
      field: "collected_amount",
      headerName: "Collected Amount",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell:(row: any) => filterAmount(row.row.collected_amount)
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (row: any) =>
        [
          { label: "Medicare", value: "medicare" },
          {
            label: "State Fee Schedule",
            value: "state_fee_schedule",
          },
          { label: "Bill Charges", value: "bill_charges" },
        ].find((item: any) => item.value === row.row.payment_type)?.label,
    },
  ];

  let url = "/api/claims-payment-list";
  return (
    <DatatableFilter heading="Claim Payment" columns={columns} url={url} />
  );
};

export default ClaimPaymentList;
