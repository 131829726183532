import React, { useEffect, useState } from "react";
import { client } from "../../utils/useRequest";
import Select from "react-select";
import { isTemplateExpression } from "typescript";
import _ from "lodash";

interface SelectFilterTableProps {
  selected?: any;
  filter: any;
}

const SelectFilterTable: React.FC<SelectFilterTableProps> = ({
  selected,
  filter,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([] as any);
  const [query, setQuery] = useState("");
  const fetchTable = async () => {
    setIsLoading(true);
    const response = await client(
      `api/table?search=${query}&table=${filter[1].table}`
    );
    setData(response.data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTable();
  }, [query]);

  return (
    <div>
      <Select
        isLoading={isLoading}
        options={data}
        isSearchable
        placeholder={`Select ${_.startCase(filter[1].name)}`}
        onInputChange={(value: any) => {
          setQuery(value);
        }}
        onChange={(option: any) =>
          selected({ name: filter[1].filterBy, value: option.value })
        }
      />
    </div>
  );
};

export default SelectFilterTable;
