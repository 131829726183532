import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";

const ProductSpeciality = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li
            onClick={() => navigate(`/${role}/manufacturers/${params.id}/edit`)}
          >
            <a className="dropdown-item">Edit Product Speciality</a>
          </li>
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 70,
    },
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name" },
    {
      field: "status",
      headerName: "Status",
      renderCell: (row: any) => (row.row?.is_active ? "Active" : "Inactive"),
    },
  ];

  let url = "/api/manufacturers/product-speciality";
  return (
    <DatatableFilter
      heading="Product Speciality"
      columns={columns}
      url={url}
      addButton={true}
    />
  );
};

export default ProductSpeciality;
