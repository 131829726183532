import { ItemRenderProps, SortableList } from "@thaddeusjiang/react-sortable-list";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface ColumnReorderProps {
    columView: any
    setColumnView: any
    url: string
  }
  const ColumnReorder: React.FC<ColumnReorderProps> = ({ columView, setColumnView, url }) => {
    const [toggle, setToggle] = useState(false);
    const [col, setCol] = useState(columView?.map((item: any, i: number) => {
      return { ...item, name: _.startCase(item.field), id: i + 1 }
    }))
    useEffect(() => {
      setColumnView(col)
      localStorage.setItem(
        url + "column-reorder",
        JSON.stringify(col)
      );
    }, [col])
    console.log(columView)
    return (
      <>
        <Badge className="cursor-pointer" onClick={() => setToggle(!toggle)}>Column Reorder</Badge>
        <Modal isOpen={toggle}>
          <ModalHeader >Column Reorder</ModalHeader>
          <ModalBody>
            <SortableList
              items={col}
              setItems={setCol}
              itemRender={({ item }: ItemRenderProps) => (
                <Badge className="text-center bg-primary mt-2 text-white py-1">
                  {item.headerName}
                </Badge>
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setToggle(!toggle)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
  
      </>
    )
  }

  export default ColumnReorder