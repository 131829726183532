import { GridColDef } from "@mui/x-data-grid";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import DatatableFilter from "../../component/datatable/DatatableFilter";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";

const Category = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li
            onClick={() => navigate(`/${role}/manufacturers/${params.id}/edit`)}
          >
            <a className="dropdown-item">Edit Category</a>
          </li>
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 70,
    },
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name" },
    {
      field: "status",
      headerName: "Status",
      renderCell: (row: any) => (row.row?.is_active ? "Active" : "Inactive"),
    },
  ];

  let url = "/api/manufacturers/category";
  return (
    <DatatableFilter
      heading="Category"
      columns={columns}
      url={url}
      addExtra={<AddCategory />}
    />
  );
};

export default Category;

const AddCategory = () => {
  const [toggle, setToggle] = useState(false);

  <>
    <Button size="sm" color="primary" onClick={() => setToggle(!toggle)}>
      Add Category
    </Button>
    <Modal isOpen={toggle}>
      <ModalHeader>Add Category</ModalHeader>
      <Formik initialValues={{}} onSubmit={console.log}>
        {({ handleSubmit, isSubmitting }) => {
          return (
            <>
              <ModalBody>
                <Form onSubmit={handleSubmit}>
                  <TextField
                    label="Name"
                    name="claim_document"
                    type="file"
                    placeholder="Add Name"
                  />
                </Form>
                <Col className="d-flex justify-content-between">
                  <LoadingButton title={"Submit"} isLoading={isSubmitting} />
                  <Button
                    type="button"
                    color="secondary"
                    className="mt-3"
                    onClick={() => setToggle(!toggle)}
                  >
                    Cancel
                  </Button>
                </Col>
              </ModalBody>
            </>
          );
        }}
      </Formik>
    </Modal>
  </>;
  return (
    <>
      <Button size="sm" color="primary" onClick={() => setToggle(!toggle)}>
        Upload File
      </Button>
      <Modal isOpen={toggle}>
        <ModalHeader>Upload Hcpcs Code State</ModalHeader>
        <Formik initialValues={{}} onSubmit={console.log}>
          {({ handleSubmit, isSubmitting }) => {
            return (
              <>
                <ModalBody>
                  <Form onSubmit={handleSubmit}>
                    <TextField
                      label="Choose Hcpcs Code State"
                      name="claim_document"
                      type="file"
                      placeholder="Select Hcpcs Code State File"
                    />
                  </Form>
                  <Col className="d-flex justify-content-between">
                    <LoadingButton title={"Submit"} isLoading={isSubmitting} />
                    <Button
                      type="button"
                      color="secondary"
                      className="mt-3"
                      onClick={() => setToggle(!toggle)}
                    >
                      Cancel
                    </Button>
                  </Col>
                </ModalBody>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
