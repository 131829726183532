import { Form, Formik } from "formik";
import React from "react";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";
import * as yup from "yup";
import { Col, Row } from "reactstrap";
import Editor from "../../component/form-component/Editor";

const AddPhysicianSchema = yup.object().shape({
  physician_phone1: yup.string().required("Phone Number is required"),
  physician_address1: yup.string().required("Address is required"),
});
interface EditPhysiciansComponentProps {
  initialValue?: any;
  onSubmit: any;
}
const EditPhysiciansComponent: React.FC<EditPhysiciansComponentProps> = ({
  initialValue,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{ ...initialValue }}
      validationSchema={AddPhysicianSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <TextField
                  label="NPI Number"
                  name="npi_number"
                  type="text"
                  placeholder="Enter NPI Number"
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="Name"
                  name="physician_name"
                  type="text"
                  placeholder="Enter Physician Name"
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="Phone Number"
                  name="physician_phone1"
                  type="text"
                  placeholder="Enter Phone Number"
                  required
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="FAX"
                  name="physician_fax"
                  type="text"
                  placeholder="Enter FAX"
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="Primary Address"
                  name="physician_address1"
                  type="text"
                  placeholder="Enter Primary Address"
                  required
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="Secondary Address"
                  name="physician_address2"
                  type="text"
                  placeholder="Enter Secondary Address"
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="City"
                  name="physician_city"
                  type="text"
                  placeholder="Enter City"
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="State Code"
                  name="physician_state_code"
                  type="text"
                  placeholder="Enter State Code"
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="Postal Code"
                  name="physician_postal_code"
                  type="text"
                  placeholder="Enter Postal Code"
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="Email"
                  name="physician_email"
                  type="text"
                  placeholder="Enter Email"
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="Website"
                  name="physician_website"
                  type="text"
                  placeholder="Enter Website"
                />
              </Col>
              <Col md={4}>
                <TextField
                  label="Company Name"
                  name="physician_company_name"
                  type="text"
                  placeholder="Enter Company Name"
                />
              </Col>
              <Col md={12}>
                <Editor name="physician_notes" label="Note" />
              </Col>
            </Row>

            <div className="mt-3">
              <LoadingButton
                title={`${!!initialValue ? "Update" : "Add"} Physician`}
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditPhysiciansComponent;
