
import { GridColDef } from "@mui/x-data-grid";
import DatatableFilter from "../../component/datatable/DatatableFilter";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Fragment } from "react";

const DuplicateClaim = () => {
  const { role } = useSelector((state: any) => state.roles);
  const { user } = useSelector((state: any) => state.user);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Claim Ids",
      width: 130,
      renderCell: (row: any) => {
        return (
          
            <ul className="list-group list-group-flush">
            {row.row.ids.split(',').map((item: any, index: number) => {
                return (<li key={index} className="list-group-item">
                    <Link key={index} to={`/${role}/claims/${item}/edit`} className="text-primary">
                B-{item}
              </Link> <br />
                </li>)
            })}
            </ul>
        );
      },
    },
    { field: "patient_first_name", headerName: "First name", width: 130 },
    { field: "patient_last_name", headerName: "Last name", width: 130 },
    {
      field: "processor_id",
      headerName: "Processor",
      width: 130,
      renderCell: (row: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={row.row.processor?.processor_name}
          >
            {row.row?.processor?.processor_name}
          </div>
        );
      },
    },
    // {
    //   field: "patient_state_code",
    //   headerName: "State",
    //   width: 130,
    // },
    // {
    //   field: "checkoff_date",
    //   headerName: "Check Off",
    //   width: 130,
    //   type: "date",
    // },
    // {
    //   field: "rework_date",
    //   headerName: "Rework Date",
    //   width: 130,
    //   type: "date",
    // },
    // {
    //   field: "date_of_service",
    //   headerName: "Date Of Service",
    //   width: 130,
    //   type: "date",
    // },
    {
      field: "insurance_company_name",
      headerName: "Insurance Company Name",
      width: 200,
    },
    // { field: "employer_name", headerName: "Employer Name", width: 130 },
    // { field: "insurance_id_number", headerName: "Claim Number", width: 130 },
    {
      field: "date_received",
      headerName: "Date Referal Received",
      width: 160,
      type: "date",
    },
    {
      field: "claim_status",
      headerName: "Claim Status",
      width: 130,
      renderCell: (row: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            title={row.row?.status?.claim_status_name}
          >
            {row.row?.status?.claim_status_name}
          </div>
        );
      },
    },
    // {
    //   field: "created_by",
    //   headerName: "Entered By",
    //   width: 130,
    //   renderCell: (row: any) => {
    //     return (
    //       <div
    //         style={{
    //           whiteSpace: "nowrap",
    //           textOverflow: "ellipsis",
    //           overflow: "hidden",
    //         }}
    //         title={row.row?.created_by?.name}
    //       >
    //         {row.row?.created_by?.name}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   field: "action_needed",
    //   headerName: "Action Needed",
    //   width: 130,
    //   filterable: true,
    // },
    // {
    //   field: "patient_birthday",
    //   headerName: "Patient Birthday",
    //   width: 130,
    //   type: "date",
    // },
  ];

  let url = "/api/duplicate/claim";

  return (
    <DatatableFilter
      heading="Duplicate Claims"
      columns={columns}
      url={url}
      addButton={false}
    />
  );
};

export default DuplicateClaim;

