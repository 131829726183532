import { Form, Formik } from "formik";
import React from "react";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";
import CheckBoxField from "../../component/form-component/CheckBoxField";
import * as yup from "yup";
import { Col, Row } from "reactstrap";
import Editor from "../../component/form-component/Editor";
import SelectTable from "../../component/form-component/SelectTable";

const AddProcessorSchema = yup.object().shape({
  processor_name: yup
    .string()
    .required("Processor Name is required"),
});
interface EditProcessorComponentProps {
  initialValue?: any;
  onSubmit: any;
}
const EditProcessorComponent: React.FC<EditProcessorComponentProps> = ({
  initialValue,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{ ...initialValue }}
      validationSchema={AddProcessorSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting,values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <SelectTable
                    tabIndex={1}
                    name="user_id"
                    label="User"
                    table="users"
                    value={values.user_id}

                />
              </Col>
              <Col md={6}>
                <TextField
                  tabIndex={2}
                  label="Processor Name"
                  name="processor_name"
                  type="text"
                  placeholder="Enter Processor Name"
                />
              </Col>
              <Col md={12} className={"mt-2"}>
                <CheckBoxField
                    tabIndex={3}
                    checked={!!values.patients}
                    name="patients"
                    label="Patients"
                />
                <CheckBoxField
                    tabIndex={4}
                    checked={!!values.claims}
                    name="claims"
                    label="Claims"
                />
              </Col>
              <Col md={3} className={"mt-2"}>
                <CheckBoxField
                    tabIndex={5}
                    checked={!!values.is_pcc}
                    name="is_pcc"
                    label="Is PCC?"
                />
              </Col>
              <Col md={3} className={"mt-2"}>
                <CheckBoxField
                    tabIndex={6}
                    checked={!!values.is_pcc_manager}
                    name="is_pcc_manager"
                    label="Is PCC Manager?"
                />
              </Col>
            </Row>
            <Row>
              <Col md={3} className={"mt-2"}>
                <CheckBoxField
                    tabIndex={7}
                    checked={!!values.is_purchaser}
                    name="is_purchaser"
                    label="Is Purchaser?"
                />
              </Col>
              <Col md={3} className={"mt-2"}>
                <CheckBoxField
                    tabIndex={8}
                    checked={!!values.is_purchaser_manager}
                    name="is_purchaser_manager"
                    label="Is Purchaser Manager?"
                />
              </Col>
            </Row>
              <Row>
              <Col md={3} className={"mt-2"}>
                <CheckBoxField
                    tabIndex={9}
                    checked={!!values.is_accounting}
                    name="is_accounting"
                    label="Is Accounting?"
                />
              </Col>
              <Col md={3} className={"mt-2"}>
                <CheckBoxField
                    tabIndex={10}
                    checked={!!values.is_accounting_manager}
                    name="is_accounting_manager"
                    label="Is Accounting Manager?"
                />
              </Col>
              </Row>
                <Row>
              <Col md={3} className={"mt-2"}>
                <CheckBoxField
                    tabIndex={11}
                    checked={!!values.is_inactive}
                    name="is_inactive"
                    label="Is Inactive?"
                />
              </Col>
                </Row>


            <div className="mt-3">
              <LoadingButton
                title={`${!!initialValue ? "Save" : "Save"} Processor`}
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditProcessorComponent;
