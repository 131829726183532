import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import { client } from "../../utils/useRequest";
import EditClaimComponent from "./EditClaimComponent";
import toastr from "toastr";
import UploadClaimDocument from "./UploadClaimDocument";

const AddClaim = () => {
  const [data, setData] = useState({} as any);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const handleSubmit = async (values: any) => {
    const response = await client.post(`/api/claims`, values);
    if (response?.data?.status) {
      toastr.success(response?.data.message);
      navigate(`/${role}/claims/${response.data.data.id}/edit`);
    } else {
      toastr.error(response?.data.message);
    }
  };

  const uploadDocument = async (values: any) => {
    const response = await client.post(`/api/claim-upload-document`, values, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.data.status) {
      const newProduct = response.data.data.products.map((item: any) => {
        return {
          ...item,
          hcpcs_code: item.hcpcs,
          charges: `${item.charge.toFixed(2)}`,
          quantity: item.unit,
          hcpcs_modifier: item.modifier,
        };
      });
      setToggle(false);
      setData({ ...response.data.data, products: newProduct });
    }
  };

  useEffect(() => {}, [data]);

  const breadCrum = [
    { title: "Claims", to: "/claims" },
    { title: "Add Claim" },
  ];

  return (
    <Layout breadCrum={breadCrum}>
      <div className="card py-2">
        <div className="card-header">
          <span className="text-muted mb-3">Add New Claim</span>

          <UploadClaimDocument
            onSubmit={uploadDocument}
            toggle={toggle}
            setToggle={setToggle}
          />
        </div>
        <EditClaimComponent
          onSubmit={handleSubmit}
          initialValue={{
            ...data,
            patient_first_name: data?.patient_first_name_and_middle_name,
            patient_gender: data?.sex,
            physician_npi: data?.npi_number,
            refering_provider: data?.name_of_referring_provider,
            missing_info: "no",
            claim_status: 6,
            ins_pay: 1,
            original_file: data?.file_upload_response,
          }}
        />
      </div>
    </Layout>
  );
};

export default AddClaim;
