import { GridColDef } from "@mui/x-data-grid";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { client } from "../utils/useRequest";
import Datatable from "./datatable/Datatable";
import FileDropzone from "./FileDropzone";
import FileViewer from "./FileViewer";
import SelectTable from "./form-component/SelectTable";
import toastr from "toastr";
import LoadingButton from "./form-component/LoadingButton";
import TextField from "./form-component/TextField";
import * as yup from "yup";

interface AttachmentComponentProps {
  modelType: string;
  modelId: any;
}
const AttachmentComponent: React.FC<AttachmentComponentProps> = ({
  modelType,
  modelId,
}) => {
  const [tableChanged, setTableChanged] = useState({} as any);
  const attachmentColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,

      renderCell: (rows: any) => {
        return rows.row.file_name;
      },
    },
    { field: "file_timestamp", headerName: "Upload Date", width: 170 },
    {
      field: "uploader_name",
      headerName: "Uploaded By",
      width: 170,
    },
    {
      field: "action",
      headerName: "Action",
      width: 170,
      renderCell: (rows: any) => {
        return (
          <div>
            {!!rows.row.bucket && (
              <>
                <FileViewer docs={rows.row} />
                <Link
                  className="btn mx-2 btn-success btn-sm"
                  to={rows?.row?.bucket}
                  target="_blank"
                  download
                >
                  <i className="fa fa-download"></i>
                </Link>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleGenerateFile = async (
    values: any,
    { resetForm }: { resetForm: any }
  ) => {
    const response = await client.post(
      `/api/claims-document-generate/${modelId}`,
      values
    );
    if (response.data.status) {
      toastr.success("File Generated Successfully");
      resetForm();
      setTableChanged(response.data);
    }
  };

  return (
    <div className="card">
      {modelType === 'claim' && <GenerateFile onSubmit={handleGenerateFile} />
      }
      <AddAttachment
        tableChanged={(value: any) => setTableChanged(value)}
        modelId={modelId}
        modelType={modelType}
      />
      <div className="card-body" style={{ height: "auto" }}>
        <Datatable
          columns={attachmentColumns}
          url={`/api/attachments?type=${modelType}&model_id=${modelId}`}
          mutate={tableChanged}
        />
      </div>
    </div>
  );
};

export default AttachmentComponent;

interface AddAttachmentProps {
  modelType: string;
  modelId: any;
  tableChanged: any;
}
const AddAttachment: React.FC<AddAttachmentProps> = ({
  modelType,
  modelId,
  tableChanged,
}) => {
  const handleAttachmentAdd = async (
    values: any,
    { resetForm }: { resetForm: any }
  ) => {
    const response = await client.post(
      `/api/attachments/${modelType}/${modelId}`,
      values,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response.data.status) {
      toastr.success(response?.data.message);
      tableChanged(response);
      resetForm();
    }
  };

  return (
    <>
      <div className="card-header">Attachments</div>
      <div className="card-body">
        <Formik
          initialValues={{ attachments: [] } as any}
          onSubmit={handleAttachmentAdd}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
            return (
              <Form>
                <Row>
                  <Col md={3}>
                    <SelectTable
                      modelType={modelType}
                      table="document_types"
                      label="Document Types"
                      name="attachment_type_id"
                    />
                  </Col>
                  <Col md={12} className="mt-5">
                    <Field name={"attachments"}>
                      {({
                        field,
                        form: { touched, errors },
                        meta,
                      }: FieldProps) => (
                        <FileDropzone
                          media={values.attachments ?? []}
                          onChange={(files: any) => {
                            setFieldValue("attachments", files);
                          }}
                          multiple
                        />
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <LoadingButton title="Upolad" isLoading={isSubmitting} />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

interface GenerateFileProps {
  onSubmit: any;
}
const GenerateFile: React.FC<GenerateFileProps> = ({ onSubmit }) => {
  const GenerateFileValidationSchema = yup.object().shape({
    demographics: yup.string().required("Demographics is required"),
    // prescription: yup.string().required("Prescription is required"),
    // visit_notes: yup.string().required("Visit Notes is required"),
  });
  return (
    <Formik
      initialValues={{}}
      validationSchema={GenerateFileValidationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Row className={'mb-3'}>
              <Col md={2}>
                <TextField
                  label="Demographics"
                  name="demographics"
                  type="text"
                  placeholder="Demos pages like 1,2,4"
                  required
                />
              </Col>
              <Col md={2}>
                <TextField
                  label="Prescription"
                  name="prescription"
                  type="text"
                  placeholder="Rx pages like 5,6"

                />
              </Col>
              <Col md={2}>
                <TextField
                  label="Visit Notes"
                  name="visit_notes"
                  type="text"
                  placeholder="Visit Notes pages like 7,8"

                />
              </Col>
              <Col md={2}>
                <TextField
                  label="Final Report"
                  name="final_report"
                  type="text"
                  placeholder="Report pages like 9,10"
                />
              </Col>

              <Col md={2}>
                <TextField
                    label="Patient Receipts"
                    name="patient_receipts"
                    type="text"
                    placeholder="Receipts pages like 9,10"
                />
              </Col>

              <Col md={2}>
                <div className=" mt-5 mb-1">
                  <LoadingButton
                      title={"Generate PDF"}
                      isLoading={isSubmitting}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};
