import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";

const Manufacturer = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li
            onClick={() => navigate(`/${role}/manufacturers/${params.id}/edit`)}
          >
            <a className="dropdown-item">Edit Manufacturer</a>
          </li>
        </ul>
      </div>
    );
  };
  const filter = {
    manufacturer_id: {
      name: "manufacturer",
      type: "text",
      filterBy: "id",
    },
    category_id: {
      name: "category",
      type: "table",
      table: "manufacturer_category",
      filterBy: "manufacturer_category",
    },
  };
  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 70,
    },
    { field: "id", headerName: "ID", width: 70 },
    { field: "manufacturer_name", headerName: "Name" },
    { field: "manufacturer_category", headerName: "Category" },
    {
      field: "manufacturer_category_status",
      headerName: "manufacturer_category_status",
    },
    { field: "manufacturer_city", headerName: "City" },
    { field: "manufacturer_state", headerName: "State" },
    {
      field: "manufacturer_postal_code",
      headerName: "Postal Code",
    },
    { field: "manufacturer_phone", headerName: "Phone" },
    {
      field: "Contract Rate",
      headerName: "manufacturer_contract_rate",
    },
    {
      field: "Account Number",
      headerName: "manufacturer_account_number",
    },
    { field: "manufacturer_tax_id", headerName: "Tax ID" },
  ];

  let url = "/api/manufacturers";
  return (
    <DatatableFilter
      heading="Manufacturers"
      columns={columns}
      url={url}
      defaultFilter={{ mode: "dropdown", filterBy: "all" }}
      filterMode={["dropdown"]}
      filter={filter}
    />
  );
};

export default Manufacturer;
