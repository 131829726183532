import { Field, FieldArray, Form, Formik } from "formik";
import React from "react";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";
import * as yup from "yup";
import { Button, Col, Row } from "reactstrap";
import Editor from "../../component/form-component/Editor";
import SelectCustom from "../../component/form-component/SelectCustom";
import CheckBoxField from "../../component/form-component/CheckBoxField";

const AddClientSchema = yup.object().shape({});
interface EditClientComponentProps {
  initialValue?: any;
  onSubmit: any;
}
const EditClientComponent: React.FC<EditClientComponentProps> = ({
  initialValue,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{ ...initialValue }}
      validationSchema={AddClientSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className="card">
              <div className="card-header">Client Info</div>
              <div className="card-body">
                <Row>
                  <Col md={3}>
                    <TextField
                      label="Name"
                      name="npi_number"
                      type="text"
                      placeholder="Enter Name"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="Address 1"
                      name="npi_number"
                      type="text"
                      placeholder="Enter Address 1"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="Address 2"
                      name="npi_number"
                      type="text"
                      placeholder="Enter Address 2"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="City"
                      name="npi_number"
                      type="text"
                      placeholder="Enter City"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="State"
                      name="npi_number"
                      type="text"
                      placeholder="Enter State"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="Postal Code"
                      name="npi_number"
                      type="text"
                      placeholder="Enter Postal Code"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="Payor ID"
                      name="npi_number"
                      type="text"
                      placeholder="Enter Payor ID"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="Phone"
                      name="npi_number"
                      type="text"
                      placeholder="Enter Phone"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="Fax"
                      name="npi_number"
                      type="text"
                      placeholder="Enter Fax"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="Billing (Drop Ship)"
                      name="npi_number"
                      type="text"
                      placeholder="Enter Billing"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="Billing (Custom)"
                      name="npi_number"
                      type="text"
                      placeholder="Enter Billing (Custom)"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="Billing Email"
                      name="npi_number"
                      type="text"
                      placeholder="Billing Email"
                    />
                  </Col>
                  <Col md={3}>
                    <SelectCustom
                      label="Billing Option"
                      name="missing_info"
                      options={[
                        { label: "Email", value: "yes" },
                        { label: "Electronic Mail", value: "no" },
                        { label: "Fax", value: "no" },
                      ]}
                    />
                  </Col>
                  <Col md={3}>
                    <SelectCustom
                      label="Payment Terms"
                      name="missing_info"
                      options={[
                        { label: "30", value: "yes" },
                        { label: "45", value: "no" },
                        { label: "60", value: "no" },
                        { label: "90", value: "no" },
                      ]}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <div className="card">
              <div className="card-header">Contacts</div>
              <div className="card-body">
                <h3>Providers</h3>
                <Row>
                  <Col md="3">
                    <TextField
                      label="Provider Relations"
                      name="npi_number"
                      type="text"
                      placeholder="Provider Relations"
                    />
                  </Col>
                  <Col md="3">
                    <TextField
                      label="Provider Email"
                      name="npi_number"
                      type="text"
                      placeholder="Provider Email"
                    />
                  </Col>
                  <Col md="3">
                    <TextField
                      label="Provider Phone"
                      name="npi_number"
                      type="text"
                      placeholder="Provider Phone"
                    />
                  </Col>
                </Row>
                <Row>
                  <FieldArray
                    name="mngr"
                    render={(arrayHelpers) => (
                      <div>
                        {values.mngr && values.mngr.length > 0 ? (
                          values.mngr.map((friend: any, index: number) => (
                            <div key={index}>
                              <Row>
                                <Col md={3}>
                                  <TextField
                                    type={"text"}
                                    label="Name"
                                    placeholder="Enter Name"
                                    name={`mngr.${index}.[name]`}
                                  />
                                </Col>
                                <Col md={3}>
                                  <TextField
                                    type={"text"}
                                    label="Department"
                                    placeholder="Enter Department"
                                    name={`mngr.${index}.[depart]`}
                                  />
                                </Col>
                                <Col md={3}>
                                  <TextField
                                    type={"text"}
                                    label="Email"
                                    placeholder="Enter Email"
                                    name={`mngr.${index}.[email]`}
                                  />
                                </Col>
                                <Col md={2}>
                                  <TextField
                                    type={"text"}
                                    label="Phone"
                                    placeholder="Enter Phone"
                                    name={`mngr.${index}.[phone]`}
                                  />
                                </Col>
                                <Col md={1} className="mt-5">
                                  <button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.insert(index, "")
                                    }
                                  >
                                    +
                                  </button>
                                </Col>
                              </Row>
                            </div>
                          ))
                        ) : (
                          <Button
                            type="button"
                            onClick={() => arrayHelpers.push("")}
                            size="sm"
                            className="mt-3"
                          >
                            Add a Manager
                          </Button>
                        )}
                      </div>
                    )}
                  />
                </Row>
              </div>
            </div>
            <div className="card">
              <div className="card-header">Communication Preference</div>
              <div className="card-body">
                <Row>
                  <Col md={3}>
                    Approved Email Method <br />
                    <div className="my-1">
                      <CheckBoxField name="system" label="System" />
                    </div>
                  </Col>
                  <Col md={3}>
                    Update Type <br />
                    <label>
                      <Field
                        className="mx-1 my-2"
                        type="radio"
                        name="update_type"
                        value="One"
                      />
                      Play-By-Play
                    </label>{" "}
                    <br />
                    <label>
                      <Field
                        className="mx-1 my-2"
                        type="radio"
                        name="update_type"
                        value="One"
                      />
                      Big Stuff
                    </label>{" "}
                    <br />
                    <label>
                      <Field
                        className="mx-1 my-2"
                        type="radio"
                        name="update_type"
                        value="One"
                      />
                      None
                    </label>
                    <br />
                    <label>
                      <Field
                        className="mx-1 my-2"
                        type="radio"
                        name="update_type"
                        value="One"
                      />
                      Custom
                    </label>
                    <br />
                  </Col>
                  <Col md={3}>
                    Delivery Confirmation <br />
                    <label>
                      <Field
                        className="mx-1 my-2"
                        type="radio"
                        name="update_type"
                        value="One"
                      />
                      Yes
                    </label>
                    <br />
                    <label>
                      <Field
                        className="mx-1 my-2"
                        type="radio"
                        name="update_type"
                        value="One"
                      />
                      No
                    </label>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="card">
              <div className="card-header">Special Instruction</div>
              <div className="card-body">
                <Editor
                  label="Special Instruction"
                  name="special_instruction"
                />
              </div>
            </div>

            <div className="mt-3">
              <LoadingButton
                title={`${!!initialValue ? "Update" : "Add"} Client`}
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditClientComponent;
