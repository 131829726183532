import React, { useState } from "react";
import { Button, Modal, ModalHeader } from "reactstrap";

import EditSubCategoryComponent from "./EditSubCategoryComponent";

const AddSubCategory = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div>
      <Button
        className={"pull-right"}
        size="sm"
        color="primary"
        onClick={() => setToggle(!toggle)}
      >
        Add Sub Category
      </Button>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>Add Sub Category</ModalHeader>
        <EditSubCategoryComponent
          onSubmit={console.log}
          setToggle={() => setToggle(!toggle)}
        />
      </Modal>
    </div>
  );
};

export default AddSubCategory;
