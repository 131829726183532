import { Form, Formik } from "formik";
import React from "react";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";
import * as yup from "yup";
import { Col, Row } from "reactstrap";
import Editor from "../../component/form-component/Editor";

const AddInsuranceSchema = yup.object().shape({
  insurance_company_name: yup
    .string()
    .required("Insurance Company Name is required"),
});
interface EditInsuranceComponentProps {
  initialValue?: any;
  onSubmit: any;
}
const EditInsuranceComponent: React.FC<EditInsuranceComponentProps> = ({
  initialValue,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{ ...initialValue }}
      validationSchema={AddInsuranceSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={3}>
                <TextField
                  label="Insurance Comapny Name"
                  name="insurance_company_name"
                  type="text"
                  placeholder="Enter Insurance Comapny Name"
                  required
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Phone"
                  name="insurance_phone1"
                  type="text"
                  placeholder="Enter Insurance Phone"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance City"
                  name="insurance_city"
                  type="text"
                  placeholder="Enter Insurance City"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Email"
                  name="insurance_email"
                  type="text"
                  placeholder="Enter Insurance Email"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Claims Center"
                  name="insurance_claims_center"
                  type="text"
                  placeholder="Enter Insurance Claims Center"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Fax"
                  name="insurance_fax"
                  type="text"
                  placeholder="Enter Insurance Fax"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance State Code"
                  name="insurance_state_code"
                  type="text"
                  placeholder="Enter Insurance State Code"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Postal Code"
                  name="insurance_postal_code"
                  type="text"
                  placeholder="Enter Insurance Postal Code"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Website"
                  name="insurance_website"
                  type="text"
                  placeholder="Enter Insurance Website"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Bill Review"
                  name="insurance_bill_review"
                  type="text"
                  placeholder="Enter Insurance Bill Review"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Address 1"
                  name="insurance_address1"
                  type="text"
                  placeholder="Enter Insurance Address 1"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Address 2"
                  name="insurance_address2"
                  type="text"
                  placeholder="Enter Insurance Address 2"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Adjuster"
                  name="insurance_adjuster"
                  type="text"
                  placeholder="Enter Insurance Adjuster"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Insurance Adjuster Phone"
                  name="insurance_adjuster_phone_number"
                  type="text"
                  placeholder="Enter Insurance Adjuster Phone"
                />
              </Col>
              <Col md={3}>
                <TextField
                  label="Submission Method"
                  name="insurance_submission_method"
                  type="text"
                  placeholder="Enter Submission Method"
                />
              </Col>

              <Col md={12}>
                <Editor name="insurance_notes" label="Note" />
              </Col>
            </Row>

            <div className="mt-3">
              <LoadingButton
                title={`${!!initialValue ? "Update" : "Add"} Insurance`}
                isLoading={isSubmitting}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditInsuranceComponent;
