import { Pagination } from "@mui/material";
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { client } from "../../utils/useRequest";

interface DatatableProps {
  columns: any;
  url: any;
  mutate?: any;
  extraComponent?: any;
}
const Datatable: React.FC<DatatableProps> = ({
  columns,
  url,
  mutate,
  extraComponent,
}) => {
  const initialQuery = {
    page: 1,
    search: "",
    sort: "-id",
  } as any;
  const [endpoint, setEndpoint] = useState(initialQuery);
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const [sortQuery, setSortQuery] = useState([
    {
      field: "id",
      sort: "asc",
    },
  ] as any);

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setSortQuery(sortModel);
    if (sortModel.length == 0) {
      setEndpoint({ ...endpoint, sort: "-id" });
    } else {
      const direction = sortModel[0]?.sort === "desc" ? "-" : "";
      const sort = direction + sortModel[0]?.field ?? "";
      setEndpoint({
        ...endpoint,
        sort: !!sort ? sort : "",
      });
    }
  };

  const fetchApi = async () => {
    setIsLoading(true);
    // const filterOption = filterByOption();
    const response = await client(url + "?" + new URLSearchParams(endpoint));
    setData(response.data?.data ?? []);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchApi();
  }, [endpoint, mutate, url]);

  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setEndpoint({ ...endpoint, page: value });
  };
  return (
    <Row>
      <Col md={4}>
        <input
          type="text"
          placeholder="Search"
          className="form-control  mb-2"
          onChange={(e) => setEndpoint({ ...endpoint, search: e.target.value })}
        />
      </Col>
      <Col md={8}>{extraComponent}</Col>
      <Col md={12}>
        <DataGrid
          autoHeight={true}
          getRowHeight={() => "auto"}
          rows={data?.data ?? []}
          columns={columns}
          sortModel={sortQuery}
          sortingMode="server"
          hideFooter
          onSortModelChange={handleSortModelChange}
          loading={isLoading}
        />
      </Col>
      <Col>
        <div className="mt-2">
          Showing {data?.from} to {data?.to} of{" "}
          {data?.total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          entries
        </div>
        <div className="d-flex justify-content-center m-1">
          <Pagination
            count={data?.last_page}
            variant="outlined"
            color="primary"
            page={endpoint.page}
            onChange={onChangePage}
            size="small"
            siblingCount={1}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Datatable;
