import { Form, Formik } from "formik";
import React from "react";
import { Button, Col, ModalBody, ModalFooter, Row } from "reactstrap";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";
import ToggleButtonField from "../../component/form-component/ToggleButtonField";

interface EditSubCategoryProps {
  setToggle: any;
  onSubmit: any;
  initialValue?: any;
}
const EditSubCategoryComponent: React.FC<EditSubCategoryProps> = ({
  setToggle,
  onSubmit,
  initialValue,
}) => {
  return (
    <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, setFieldValue }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <Row>
                  <Col md={12}>
                    <TextField
                      placeholder="Enter Name"
                      required
                      name="name"
                      label="Name"
                      type="text"
                    />
                  </Col>
                  <Col md={12}>
                    <ToggleButtonField name="status" label="Active" />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Col className="d-flex justify-content-between" md={12}>
                  <LoadingButton title="Save" isLoading={isSubmitting} />
                  <Button
                    style={{ height: 35, marginTop: 10 }}
                    onClick={() => setToggle(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </ModalFooter>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default EditSubCategoryComponent;
