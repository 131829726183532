import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import { client } from "../../utils/useRequest";
import toastr from "toastr";
import EditInsuranceComponent from "./EditInsuranceComponent";

const AddInsurance = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const handleSubmit = async (values: any) => {
    const response = await client.post("/api/insurances", { ...values });
    if (response.data.status) {
      toastr.success(response?.data.message);
      navigate(`/${role}/insurances`);
    }
  };
  return (
    <Layout>
      <div className="card py-2">
        <h3 className="mb-3">Add New Insurance</h3>
        <EditInsuranceComponent onSubmit={handleSubmit} />
      </div>
    </Layout>
  );
};

export default AddInsurance;
