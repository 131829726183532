import { Form, Formik } from "formik";
import React from "react";
import { Col, Row } from "reactstrap";
import DatePickerComponent from "../../component/form-component/DatePickerComponent";
import LoadingButton from "../../component/form-component/LoadingButton";
import SelectCustom from "../../component/form-component/SelectCustom";
import TextField from "../../component/form-component/TextField";
import * as yup from "yup";

interface EditClaimPaymentComponentProps {
  onSubmit: any;
  initialValue?: any;
  extraComponent?:any;
}

const AddClaimPaymentSchema = yup.object().shape({
  payment_receipt_date: yup
    .string()
    .required("Payment Receipt Date is required"),
  payment_amount: yup.string().required("Payment Amount is required"),
});
const EditClaimPaymentComponent: React.FC<EditClaimPaymentComponentProps> = ({
  onSubmit,
  initialValue,
  extraComponent
}) => {
  return (
    <div>
      <div className="card">
        <div className="card-header">Payment Info</div>
        <div className="card-body">
          <Formik
            initialValues={{ ...initialValue }}
            onSubmit={onSubmit}
            validationSchema={AddClaimPaymentSchema}
          >
            {({ handleSubmit, isSubmitting, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={3}>
                      <TextField
                      type="date"
                        tabIndex={701}
                        label="Payment Receipt Date"
                        name="payment_receipt_date"
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                      type="date"
                        tabIndex={704}
                        label="Client Paid Date"
                        name="client_paid_date"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={706}
                        label="Payment Check Number"
                        name="payment_check_number"
                        type="text"
                        placeholder="Payment Check Number"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={708}
                        label="Payment Amount ($)"
                        name="payment_amount"
                        type="number"
                        placeholder="Payment Amount ($)"
                        required
                        onChange={(val: any) => {
                          if (val > 0) {
                            setFieldValue(
                              "client_paid_amount",
                              (parseFloat(val) * 0.65).toFixed(2)
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={702}
                        label="Client Paid Amount ($)"
                        name="client_paid_amount"
                        type="number"
                        placeholder="Client Paid Amount ($)"
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        tabIndex={705}
                        label="Collected Amount(%)"
                        name="collected_amount"
                        type="number"
                        placeholder="Collected Amount"
                      />
                    </Col>
                    <Col md={3}>
                      <SelectCustom
                        tabIndex={707}
                        label="Payment Type"
                        name="payment_type"
                        options={[
                          { label: "Medicare", value: "medicare" },
                          {
                            label: "State Fee Schedule",
                            value: "state_fee_schedule",
                          },
                          { label: "Bill Charges", value: "bill_charges" },
                        ]}
                      />
                    </Col>
                  </Row>
                  {extraComponent}
                  <div className="mt-3">
                    <LoadingButton
                      title={`${
                        !!initialValue ? "Update" : "Add"
                      } Claim Payment`}
                      isLoading={isSubmitting}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditClaimPaymentComponent;
