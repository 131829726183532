import { GridColDef } from "@mui/x-data-grid";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import DatatableFilter from "../../component/datatable/DatatableFilter";
import LoadingButton from "../../component/form-component/LoadingButton";
import TextField from "../../component/form-component/TextField";

const HcpcsCodeState = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li onClick={() => navigate(`/${role}/physicians/${params.id}/edit`)}>
            <a className="dropdown-item">Edit Physician</a>
          </li>
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "hcpcs_code",
      headerName: "HCPCS Code",
      width: 130,
      renderCell: (row: any) => row.row.hcpcs_code?.hcpcs_code,
    },
    {
      field: "hcpcs_state_code",
      headerName: "HCPCS Code",
      width: 230,
    },
    { field: "medicare_fee", headerName: "Medicare Fee", width: 130 },
    { field: "state_fee", headerName: "State Fee", width: 130 },
    { field: "medicare_mod", headerName: "Medicare Mod", width: 130 },
    { field: "medicare_mod2", headerName: "Medicare Mod 2", width: 130 },
  ];

  let url = "/api/hcpcs-state-code";
  return (
    <DatatableFilter
      heading="Hcpcs Code State"
      columns={columns}
      url={url}
      extraButton={<UploadButton />}
    />
  );
};

export default HcpcsCodeState;

const UploadButton = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <Button size="sm" color="primary" onClick={() => setToggle(!toggle)}>
        Upload File
      </Button>
      <Modal isOpen={toggle}>
        <ModalHeader>Upload Hcpcs Code State</ModalHeader>
        <Formik initialValues={{}} onSubmit={console.log}>
          {({ handleSubmit, isSubmitting }) => {
            return (
              <>
                <ModalBody>
                  <Form onSubmit={handleSubmit}>
                    <TextField
                      label="Choose Hcpcs Code State"
                      name="claim_document"
                      type="file"
                      placeholder="Select Hcpcs Code State File"
                    />
                  </Form>
                  <Col className="d-flex justify-content-between">
                    <LoadingButton title={"Submit"} isLoading={isSubmitting} />
                    <Button
                      type="button"
                      color="secondary"
                      className="mt-3"
                      onClick={() => setToggle(!toggle)}
                    >
                      Cancel
                    </Button>
                  </Col>
                </ModalBody>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
