import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatatableFilter from "../../component/datatable/DatatableFilter";

const Processor = () => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li onClick={() => navigate(`/${role}/processors/${params.id}/edit`)}>
            <a className="dropdown-item">Edit </a>
          </li>
        </ul>
      </div>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 20,
    },
    { field: "processor_name", headerName: "Name", width: 300},
    { field: "patients", headerName: "Patients", width: 200, renderCell: (row: any) => (row.row?.patients ? "Yes" : "No")},
    { field: "claims", headerName: "Claims", width: 200 ,renderCell: (row: any) => (row.row?.claims ? "Yes" : "No")},
    { field: "is_pcc", headerName: "Is PCC", width: 200,renderCell: (row: any) => (row.row?.is_pcc ? "Yes" : "No") },
    { field: "is_inactive", headerName: "Is Inactive", width: 200,renderCell: (row: any) => (row.row?.is_inactive ? "Inactive" : "Active") },
  ];

  let url = "/api/processors";
  return (
    <DatatableFilter
      heading="Processors"
      columns={columns}
      url={url}
      addButton={true}
    />
  );
};

export default Processor;
