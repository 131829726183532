import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Collapse } from "reactstrap";

const MenuItem = ({ item }: { item: ItemProps }) => {
  const { permission } = useSelector((state: any) => state.roles);
  if (item?.permission && !permission.includes(item.permission)) {
    return <></>;
  }
  const Component = !!item.children ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }: { item: ItemProps }) => {
  const { role } = useSelector((state: any) => state.roles);
  return (
    <li
      className={`sidebar-item ${
        window.location.pathname.includes(item.to) ? "active" : ""
      }`}
    >
      <Link to={`/${role}${item.to}`} className="sidebar-link">
        <span className="align-middle">
          <i className={`fa ${item.icon}`}></i>
          {item.label}
        </span>
      </Link>
    </li>
  );
};

const MultiLevel = ({ item }: { item: ItemProps }) => {
  const [collapse, setCollapse] = React.useState(
    item.openFor?.find((x: any) => window.location.pathname.includes(x))
      ? false
      : true
  );

  return (
    <div className="main-list">
      <li className="sidebar-item" onClick={() => setCollapse(!collapse)}>
        <a className="sidebar-link d-flex justify-content-between">
          <span className="align-middle">
            <i className={`fa ${item.icon}`}></i> {item.label}
          </span>
          <i
            style={{ fontSize: 20 }}
            className={collapse ? "fa fa-angle-right" : "fa fa-angle-down"}
          />
        </a>
      </li>
      <Collapse style={{ marginLeft: 5 }} isOpen={!collapse}>
        {item.children.map((menu: ItemProps, index: number) => {
          return <MenuItem key={index} item={menu} />;
        })}
      </Collapse>
    </div>
  );
};

interface ItemProps {
  label: string;
  to: string;
  icon?: any;
  permission?: any;
  children?: any;
  openFor?: any;
}

interface SideItemProps {
  item: ItemProps;
}

const SideItem: React.FC<SideItemProps> = ({ item }) => {
  return <MenuItem item={item} />;
};

export default SideItem;
