import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    role: '',
    permission: {},
    error: ''
}

const roleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers:{
        roleSuccess: (state, {payload}) => {
            state.role = payload.roles;
            state.permission = payload.permission
            state.error = "";
        },
        roleFail: (state, {payload}) => {
            state.error = payload;
            state.permission = {};
            state.role = '';
        }
    }

})

const {reducer, actions} = roleSlice;
export const {roleSuccess, roleFail} = actions;
export default reducer;