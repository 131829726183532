export const CLAIM_BROWSE = "claim-browse";
export const CLAIM_READ = "claim-read";
export const CLAIM_EDIT = "claim-edit";
export const CLAIM_ADD = "claim-add";

export const PHYSICIAN_BROWSE = "physician-browse";
export const PHYSICIAN_READ = "physician-read";
export const PHYSICIAN_EDIT = "physician-edit";
export const PHYSICIAN_ADD = "physician-add";

export const INSURANCE_BROWSE = "insurance-browse";
export const INSURANCE_READ = "insurance-read";
export const INSURANCE_EDIT = "insurance-edit";
export const INSURANCE_ADD = "insurance-add";
export const INSURANCE_DELETE = "insurance-delete";

export const CLIENT_BROWSE = "client-browse";
export const CLIENT_READ = "client-read";
export const CLIENT_EDIT = "client-edit";
export const CLIENT_ADD = "client-add";

export const PROCESSOR_BROWSE = "processor-browse";
export const PROCESSOR_READ = "processor-read";
export const PROCESSOR_EDIT = "processor-edit";
export const PROCESSOR_ADD = "processor-add";

export const USER_BROWSE = "user-browse";
export const USER_READ = "user-read";
export const USER_EDIT = "user-edit";
export const USER_ADD = "user-add";