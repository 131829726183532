import React from "react";
import { useSelector } from "react-redux";
import Permission from "./pages/error/Permission";

const PermissionRoute = ({
  pass,
  children,
}: {
  pass: string;
  children: any;
}) => {
  const { permission } = useSelector((state: any) => state.roles);

  if (!permission.includes(pass)) {
    return <Permission />;
  }

  return children;
};

export default PermissionRoute;
