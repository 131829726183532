import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Datatable from "../../component/datatable/Datatable";
import { client } from "../../utils/useRequest";
import EditClaimPaymentComponent from "./EditClaimPaymentComponent";
import toastr from "toastr";
import AttachmentComponent from "../../component/AttachmentComponent";
import { filterAmount } from "../../utils/helper";

interface ClaimPaymentProps {
  modelId: any;
}
const ClaimPayment: React.FC<ClaimPaymentProps> = ({ modelId }) => {
  const [mutate, setMutate] = useState({} as any);
  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: any }
  ) => {
    const response = await client.post(`/api/claims-payment-add/${modelId}`, {
      ...values,
    });
    if (response.data.status) {
      setMutate(response.data.data);
      toastr.success("Payment added");
      resetForm();
    }
  };

  const formatDate = (date: any) => {
    if (date == "-" || !date) return "";
    let dates = date.split("/");
    let year = dates[2];
    let month = dates[0];
    let day = dates[1];
    return year + "-" + month + "-" + day;
  };

  const loadAction = (params: any) => {
    return (
      <div className="table-action dropend">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-bars" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu" aria-labelledby="" style={{}}>
          <li>
            <EditClaimPayment
              initialValues={{
                ...params.row,
                client_paid_date: formatDate(params.row.client_paid_date),
                payment_receipt_date: formatDate(
                  params.row.payment_receipt_date
                ),
              }}
              modelId={modelId}
              setMutate={setMutate}
            />
          </li>
          <li>
            <AddAttachment modelId={params.id}/>
          </li>
        </ul>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "action",
      sortable: false,
      renderCell: loadAction,
      headerName: "Actions",
      width: 70,
    },
    {
      field: "id",
      headerName: "Payment ID",
      width: 70,
      sortable: false,
      filterable: false,
    },
    {
      field: "payment_receipt_date",
      headerName: "Payment Receipt Date",
      width: 130,
      sortable: false,
      filterable: false,
    },
    {
      field: "client_paid_date",
      headerName: "Client Paid Date",
      width: 130,
      sortable: false,
      filterable: false,
    },
    {
      field: "payment_check_number",
      headerName: "Payment Check Number",
      width: 130,
      sortable: false,
      filterable: false,
    },
    {
      field: "client_paid_amount",
      headerName: "Client Paid Amount",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell:(row: any) => filterAmount(row.row.client_paid_amount)
    },
    {
      field: "payment_amount",
      headerName: "Payment Amount",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell:(row: any) => filterAmount(row.row.payment_amount) 
    },
    {
      field: "collected_amount",
      headerName: "Collected Amount",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell:(row: any) => filterAmount(row.row.collected_amount)
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (row: any) =>
        [
          { label: "Medicare", value: "medicare" },
          {
            label: "State Fee Schedule",
            value: "state_fee_schedule",
          },
          { label: "Bill Charges", value: "bill_charges" },
        ].find((item: any) => item.value === row.row.payment_type)?.label,
    },
  ];
  return (
    <>
      <EditClaimPaymentComponent onSubmit={handleSubmit} />
      <div className="card">
        <div className="card-header">Payment List</div>
        <div className="card-body payment-card-body">
          <Datatable
            mutate={mutate}
            columns={columns}
            url={`/api/claims-payment-list/${modelId}`}
          />
        </div>
      </div>
    </>
  );
};

export default ClaimPayment;

interface EditClaimPaymentProps {
  initialValues: any;
  modelId: number;
  setMutate: any;
}

const EditClaimPayment: React.FC<EditClaimPaymentProps> = ({
  initialValues,
  modelId,
  setMutate,
}) => {
  const [toggle, setToggle] = useState(false);

  const handleEdit = async (values: any) => {
    const response = await client.post(`/api/claims-payment-add/${modelId}`, {
      ...values,
    });
    if (response.data.status) {
      setMutate(response.data.data);
      setToggle(false);
      toastr.success("Payment Updated.");
    }
  };

  return (
    <>
      <a className={"dropdown-item"} onClick={() => setToggle(!toggle)}>
        Edit
      </a>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>Upload Claim Document</ModalHeader>
        <EditClaimPaymentComponent
          initialValue={initialValues}
          onSubmit={handleEdit}
        />
        <ModalFooter>
          <Button onClick={() => setToggle(!toggle)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

interface AddAttachmentProps {
  modelId: any
}
const AddAttachment: React.FC<AddAttachmentProps> = ({modelId}) => {
  const [toggle, setToggle] = useState(false)
  return(
    <>
    <a className={"dropdown-item"}onClick={() => setToggle(!toggle)}>
        Add Attachment
      </a>
      <Modal size="lg" isOpen={toggle}>
        <ModalHeader>Add Payment Attachment</ModalHeader>
          <ModalBody>
            <AttachmentComponent modelId={modelId} modelType="claim-payment" />
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setToggle(false)} >Cancel</Button>
          </ModalFooter>
      </Modal></>
  )
}
