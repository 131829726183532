import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "../../component/layout/Layout";
import { client } from "../../utils/useRequest";
import toastr from "toastr";
import EditUserComponent from "./EditUserComponent";

const AddUser = () => {
  const [data, setData] = useState({} as any);


  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.roles);
  const handleSubmit = async (values: any) => {
  const response = await client.post("/api/users", { ...values });
    if (response.data.status) {
      toastr.success(response?.data.message);
      navigate(`/${role}/users`);
      // setData(...response.data.data);

    }
  };
  useEffect(() => {}, [data]);

  return (
    <Layout>
      <div className="card py-2">
        <h3 className="mb-3">Add New User</h3>
        <EditUserComponent onSubmit={handleSubmit}
          initialValue={{
          ...data,

          }} />
      </div>
    </Layout>
  );
};

export default AddUser;
