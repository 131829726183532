import { Field, FieldProps } from "formik";
import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

interface EditorProps {
  name: string;
  label: string;
  height?: string;
  required?: boolean;
}
const Editor: React.FC<EditorProps> = ({ name, label, height, required }) => {
  return (
    <Field name={name}>
      {({
        field,
        form: { touched, errors, setFieldValue },
        meta,
      }: FieldProps) => (
        <div className={!!label ? "mt-3" : ""}>
          {!!label && (
            <label className="text-start mb-1 d-flex justify-content-start">
              {label}{" "}
              {required ? <h5 className="text-danger mx-2"> * </h5> : ""}
            </label>
          )}
          <SunEditor
            {...field}
            onChange={(value: any) => setFieldValue(name, value)}
            setOptions={{
              buttonList: [
                ["undo", "redo"],
                ["bold", "underline", "italic", "list", "font", 'hiliteColor'],
                ["table", "link", "image"],
                ["fullScreen"],
              ],
              defaultStyle:'font-family:Arial'
            }}
            height={height}
            setContents={field.value ?? ""}
          />
          {(meta.touched || meta.error) && (
            <div className="text-danger">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default Editor;
